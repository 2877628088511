import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchUser from '@redux/thunks/users/fetchUser'
import { COLLECTION_STATES } from 'helpers/collections'
import Debug from 'helpers/debug'
import { formatDateToDayOfWeekAndDate, formatDateToTime24 } from 'helpers/time'
import { useCurrentUserId } from 'hooks/users'
import styles from './RotaItem.module.scss'

const debug = Debug('components:store-rota')

const { UNASSIGNED } = COLLECTION_STATES

type Props = {
  nextCollection: string
  userId?: number
  collectionState?: string
  timezone?: string
}

const RotaItem = ({ nextCollection, userId, collectionState, timezone }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const currentUserId = useCurrentUserId()
  const userName = useAppSelector((state) => {
    if (currentUserId === userId) {
      return state.users.currentUser?.name?.firstName
    }

    return state.users.items[userId]?.name
  })

  useEffect(() => {
    if (
      collectionState !== UNASSIGNED &&
      userId !== undefined &&
      Number(currentUserId) !== userId
    ) {
      dispatch(fetchUser(userId)).catch(debug)
    }
  }, [userId])

  return (
    <div className={styles.rotaItem}>
      <div className={styles.bulletAndDate}>
        <span className={styles.icon}>
          {collectionState === UNASSIGNED ? (
            <FaRegCircle data-testid="unclaimedIcon" />
          ) : (
            <FaCheckCircle data-testid="claimedIcon" />
          )}
        </span>
        <div
          data-testid="rotaItem"
          className={styles.dateTime}
        >
          {formatDateToDayOfWeekAndDate(nextCollection, timezone)}
        </div>
      </div>

      <div className={styles.nameAndTime}>
        <span>
          {formatDateToTime24(nextCollection, timezone)} |{' '}
          {collectionState === UNASSIGNED
            ? t('your-collections.page.show.rota.unclaimed')
            : userName}
        </span>
      </div>
    </div>
  )
}

export default RotaItem
