import { FETCHING_STATUSES } from 'config/api'

const { IDLE } = FETCHING_STATUSES

export default {
  items: {},
  businesses: {},
  storesFetchingStatus: IDLE,
  businessesFetchingStatus: IDLE,
}
