import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { checkQuizAnswers } from 'helpers/inductions'
import Button from 'components/common/Button'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { Stack } from 'components/layout/primitives'
import styles from './QuizFailure.module.scss'

type Props = {
  questions: any[]
  selectedAnswers: any[]
  hasVideo: boolean
  playYoutubeVideo: () => void
  retryQuiz: () => void
}

const QuizFailure = ({
  questions,
  selectedAnswers,
  hasVideo,
  playYoutubeVideo,
  retryQuiz,
}: Props) => {
  const { t, i18n } = useTranslation()
  const { correctAnswersCount, incorrectlyAnsweredQuestions } = checkQuizAnswers(
    questions,
    selectedAnswers,
  )

  const incorrectAnswers = useMemo(() => {
    if (incorrectlyAnsweredQuestions.length === 1) {
      return incorrectlyAnsweredQuestions[0]
    } else {
      const formatter = new Intl.ListFormat(i18n.language, { style: 'long', type: 'conjunction' })
      const incorrectAnswers = incorrectlyAnsweredQuestions.map(String)
      return formatter.format(incorrectAnswers)
    }
  }, [incorrectlyAnsweredQuestions, i18n.language])

  const resultText = t('inductions.page.show.quiz.results_description_2', {
    correct: `<b>${correctAnswersCount}</b>`,
    total: `<b>${questions.length}</b>`,
  })

  const incorrectAnswersText = t('inductions.page.show.quiz.results_description_3', {
    count: incorrectlyAnsweredQuestions.length,
    incorrectAnswers: `<b>${incorrectAnswers}</b>`,
  })

  return (
    <div className={clsx('card', styles.inductionQuizResults)}>
      <div className="card-content">
        <Stack gap="1rem">
          <h2>{t('inductions.page.show.quiz.your_results')}</h2>
          <p>{t('inductions.page.show.quiz.results_description_1')}</p>
          <p>
            <SetInnerHtmlSafe>{resultText}</SetInnerHtmlSafe>
          </p>
          {hasVideo === true ? (
            <>
              <p>
                <SetInnerHtmlSafe>{incorrectAnswersText}</SetInnerHtmlSafe>
              </p>
              <Button onClick={playYoutubeVideo}>
                {t('inductions.page.show.quiz.play_video')}
              </Button>
            </>
          ) : (
            <>
              <p>{t('inductions.page.show.quiz.please_remember')}</p>
              <Button onClick={retryQuiz}>{t('inductions.page.show.quiz.try_again')}</Button>
            </>
          )}
        </Stack>
      </div>
    </div>
  )
}

export default QuizFailure
