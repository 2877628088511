import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import Alert from 'components/common/Alert'
import Collapsible from 'components/common/Collapsible'
import styles from './CollapsibleAlert.module.scss'

type Props = {
  icon: React.ReactNode
  title: string
  description: string
  variant: 'error' | 'info' | 'status' | 'message' | 'recommendation'
  className?: string
  children: React.ReactNode
}

const CollapsibleAlert = ({ icon, title, description, variant, className, children }: Props) => {
  const { t } = useTranslation()
  return (
    <Alert
      className={clsx(className, styles.alert)}
      variant={variant}
    >
      <div className={styles.header}>
        <span className={clsx(styles.icon)}>{icon}</span>
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
      <Collapsible
        collapsedToggle={<u className={styles.toggle}>{t('common.alerts.read-more')}</u>}
        expandedToggle={<u className={styles.toggle}>{t('common.alerts.show-less')}</u>}
      >
        <div className={styles.children}>{children}</div>
      </Collapsible>
    </Alert>
  )
}

export default CollapsibleAlert
