import { useEffect, useMemo } from 'react'
import { shallowEqual } from 'react-redux'
import { getDistance } from 'geolib'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchUser from '@redux/thunks/users/fetchUser'
import { COLLECTION_TYPES } from 'config/collections'
import Debug from 'helpers/debug'
import { getCurrentUserId } from 'helpers/user'
import { useFetchAvailableCollectionsWithStoresAndBusinesses } from 'hooks/collections'
import {
  exampleAdhocCollection,
  exampleOngoingCollection,
} from 'components/JoinIntro/CollectionsNearMe/exampleCollections'
import CollectionsOverview from 'components/JoinIntro/CollectionsOverview'
import LoadingSpinner from 'components/common/LoadingSpinner'
import styles from './CollectionsNearMe.module.scss'

const debug = Debug('components:join-intro:collections-near-me')

const CollectionsNearMe = () => {
  const dispatch = useAppDispatch()

  const { availableCollections, hasBusinessesFetched, hasCollectionsFetched, hasStoresFetched } =
    useFetchAvailableCollectionsWithStoresAndBusinesses()

  const homeLocation = useAppSelector((state) => state.users.currentUser?.location, shallowEqual)

  const adhocCollections = availableCollections?.filter(
    (collection) => collection.type === COLLECTION_TYPES.ADHOC,
  )
  const ongoingCollections = availableCollections?.filter(
    (collection) => collection.type === COLLECTION_TYPES.ONGOING,
  )

  const getClosestCollection = (collections) => {
    if (homeLocation !== undefined && hasStoresFetched === true) {
      const collectionsWithDistance = collections.map((collection) => {
        const distance = getDistance(homeLocation, collection.store.location)
        return {
          ...collection,
          distance,
        }
      })
      return collectionsWithDistance.reduce((previous, current) => {
        return Number(previous.distance) < Number(current.distance) ? previous : current
      })
    }
    return undefined
  }

  const closestAdhocCollection = useMemo(() => {
    if (adhocCollections !== undefined && adhocCollections.length > 0) {
      if (adhocCollections.length === 1) {
        return adhocCollections[0]
      }
      return getClosestCollection(adhocCollections)
    }
  }, [adhocCollections])
  const closestOngoingCollection = useMemo(() => {
    if (ongoingCollections !== undefined && ongoingCollections.length > 0) {
      if (ongoingCollections.length === 1) {
        return ongoingCollections[0]
      }
      return getClosestCollection(ongoingCollections)
    }
  }, [ongoingCollections])

  const closestCollectionData = (collection) => {
    const storeData = collection.store
    return {
      storeLocation: storeData?.location,
      homeLocation,
      frequency: collection.frequency,
      nextCollection: collection.nextCollection,
      storeAddress: storeData?.address,
      storeTimezone: storeData?.timezone,
      businessName: collection.business?.name,
      storeName: storeData?.name,
      type: collection.type,
    }
  }

  useEffect(() => {
    dispatch(fetchUser(getCurrentUserId())).catch(debug)
  }, [])

  if (hasCollectionsFetched !== true) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.collectionsNearMe}>
      {closestOngoingCollection !== undefined &&
      closestAdhocCollection !== undefined &&
      hasBusinessesFetched === true ? (
        <CollectionsOverview
          adhocCollection={closestCollectionData(closestAdhocCollection)}
          ongoingCollection={closestCollectionData(closestOngoingCollection)}
        />
      ) : (
        <CollectionsOverview
          adhocCollection={exampleAdhocCollection()}
          ongoingCollection={exampleOngoingCollection()}
        />
      )}
    </div>
  )
}

export default CollectionsNearMe
