import { COLLECTION_PAUSE_MODAL_CLOSE } from '@redux/actionTypes'

const closePauseModal = () => {
  return (dispatch) => {
    dispatch({
      type: COLLECTION_PAUSE_MODAL_CLOSE,
    })
  }
}

export default closePauseModal
