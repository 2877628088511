import React, { HTMLAttributeAnchorTarget } from 'react'
import clsx from 'clsx'
import styles from './Button.module.scss'

type ButtonType = {
  children?: React.ReactNode
  className?: string
  testId?: string
  disabled?: boolean
  download?: boolean
  href?: string
  target?: HTMLAttributeAnchorTarget
  leftContent?: React.ReactNode
  onClick?: (any) => void
  onKeyDown?: (any) => void
  secondary?: boolean
  buttonSize?: 'fullWidthButton'
  type?: 'button' | 'submit' | 'reset'
}

const Button = ({
  children,
  className,
  testId,
  disabled = false,
  download = false,
  href,
  target,
  leftContent,
  onClick,
  onKeyDown,
  secondary,
  buttonSize,
  type = 'button',
}: ButtonType) => {
  if (href) {
    return (
      <a
        href={href}
        role="button"
        className={clsx(
          className,
          styles.button,
          { [styles.secondary]: secondary === true },
          { 'btn-secondary': secondary === true },
        )}
        onClick={onClick}
        onKeyDown={onKeyDown}
        data-testid={testId}
        download={download}
        target={target}
      >
        {leftContent && <span className={styles.leftContent}>{leftContent}</span>}
        <span className="label">{children}</span>
      </a>
    )
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(
        className,
        styles[buttonSize],
        styles.button,
        { [styles.secondary]: secondary === true },
        { 'btn-secondary': secondary === true },
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      data-testid={testId}
      disabled={disabled}
      aria-disabled={onClick === undefined || disabled === true ? 'true' : 'false'}
      tabIndex={disabled ? -1 : 0}
    >
      {leftContent && <span className={styles.leftContent}>{leftContent}</span>}
      <span className="label">{children}</span>
    </button>
  )
}

export default Button
