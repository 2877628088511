import { useTranslation } from 'react-i18next'
import { useIsMobile } from 'hooks/application'
import Button from 'components/common/Button'
import CardWithButton from 'components/common/Cards/CardWithButton'
import SceneHeader from 'components/common/SceneHeader'
import { Grid, Stack } from 'components/layout/primitives'
import styles from './FaqScene.module.scss'

const FaqScene = () => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <div className={styles.faqScene}>
      <div className={styles.faqHeader}>
        <SceneHeader
          title={t('faq.index.header-title')}
          description={t('faq.index.header-intro')}
        />
        <h3>{t('faq.index.card-header')}</h3>
      </div>
      <Stack>
        <Grid
          columns={isMobile === true ? 1 : 3}
          gap="1rem"
        >
          <CardWithButton
            title={t('faq.index.card.card-title')}
            description={t('faq.index.card.card-description')}
            buttonText={t('faq.index.card.card-button')}
            buttonLink={t('faq.index.card.card-url')}
            buttonSecondary
          />
          <CardWithButton
            title={t('faq.index.card-spanish.card-title')}
            description={t('faq.index.card-spanish.card-description')}
            buttonText={t('faq.index.card-spanish.card-button')}
            buttonLink={t('faq.index.card-spanish.card-url')}
            buttonSecondary
          />
          <CardWithButton
            title={t('faq.index.card-singapore.card-title')}
            description={t('faq.index.card-singapore.card-description')}
            buttonText={t('faq.index.card-singapore.card-button')}
            buttonLink={t('faq.index.card-singapore.card-url')}
            buttonSecondary
          />
        </Grid>
      </Stack>
      <div className={styles.button}>
        <p>{t('faq.index.button-header')}</p>
        <Button href={t('faq.index.general.url')}>{t('faq.index.button-text')}</Button>
      </div>
    </div>
  )
}

export default FaqScene
