import { useEffect, useState } from 'react'
import type { AxiosResponse } from 'axios'
import sortBy from 'lodash/sortBy'
import { DateTime } from 'luxon'
import type { DateTimeMaybeValid } from 'luxon'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'
import { useCollection } from 'hooks/collections'

const debug = Debug('redux:thunks:collections:claimCollection')

type CollectionClaimabilityResponseType = {
  upcoming_collections_on_same_day: Record<PropertyKey, never> | Record<string, number[]>
}

const useCheckCollectionClaimability = ({ collectionId, nextIterationOnly }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [sameDayCollectionDate, setSameDayCollectionDate] = useState<DateTimeMaybeValid>()
  const [sameDayCollectionCount, setSameDayCollectionCount] = useState(0)

  const { data: collection } = useCollection({
    collectionId,
  })

  useEffect(() => {
    const fetchCollectionClaimability = async () => {
      setIsLoading(true)
      try {
        const response: AxiosResponse<CollectionClaimabilityResponseType> = await axios.get(
          `/api/v1/collections/${collectionId}/check_claimable`,
        )
        setIsLoading(false)

        const sameDayCollectionsInfo = response.data.upcoming_collections_on_same_day

        const sortByCountDescending = (entry) => -entry[1].length
        const sortByDateAscending = (entry) => entry[0]
        const sortedSameDayCollectionsInfo = Object.fromEntries(
          sortBy(Object.entries(sameDayCollectionsInfo), [
            sortByCountDescending,
            sortByDateAscending,
          ]),
        )

        const firstOverlappingDateString = Object.keys(sortedSameDayCollectionsInfo)?.[0]

        if (firstOverlappingDateString) {
          if (nextIterationOnly) {
            const nextIterationDate = DateTime.fromISO(collection?.nextCollection).toFormat(
              'yyyy-MM-dd',
            )
            const nextIterationSameDayCollectionsCount =
              sortedSameDayCollectionsInfo[nextIterationDate].length
            setSameDayCollectionDate(DateTime.fromFormat(nextIterationDate, 'yyyy-MM-dd'))
            setSameDayCollectionCount(nextIterationSameDayCollectionsCount)
          } else {
            setSameDayCollectionDate(DateTime.fromFormat(firstOverlappingDateString, 'yyyy-MM-dd'))
            setSameDayCollectionCount(
              sortedSameDayCollectionsInfo[firstOverlappingDateString].length,
            )
          }
        }
      } catch (error) {
        const reason = loggableError(error)
        debug(`Failed checking claimability of collection ${collectionId}`, reason)
      }
    }
    fetchCollectionClaimability()
  }, [])

  return {
    sameDayCollectionDate,
    sameDayCollectionCount,
    isLoading,
  }
}

export default useCheckCollectionClaimability
