import clsx from 'clsx'
import styles from './Badge.module.scss'

type BadgeType = {
  icon: React.ReactNode
  text: string
  variant: 'success' | 'error'
}

const Badge = ({ icon, text, variant }: BadgeType) => {
  return (
    <div className={clsx(styles.badge, styles[variant], 'badge')}>
      {icon}
      <div>{text}</div>
    </div>
  )
}

export default Badge
