import InductionQuizFailure from 'components/Inductions/InductionQuiz/QuizFailure'
import InductionQuizSuccess from 'components/Inductions/InductionQuiz/QuizSuccess'
import LoadingSpinner from 'components/common/LoadingSpinner'

type Props = {
  questions: any[]
  selectedAnswers: any[]
  hasVideo: boolean
  inductionTitle: string
  playYoutubeVideo: () => void
  quizCompletedSuccessfully: boolean
  areAllAnswersCorrect: boolean
  retryQuiz: () => void
}

const QuizResults = ({
  questions,
  selectedAnswers,
  hasVideo,
  inductionTitle,
  playYoutubeVideo,
  quizCompletedSuccessfully,
  areAllAnswersCorrect,
  retryQuiz,
}: Props) => {
  if (areAllAnswersCorrect === true && quizCompletedSuccessfully === false) {
    return <LoadingSpinner containerHeight="400px" />
  } else if (quizCompletedSuccessfully === true) {
    return (
      <InductionQuizSuccess
        questionCount={questions.length}
        inductionTitle={inductionTitle}
      />
    )
  } else {
    return (
      <InductionQuizFailure
        questions={questions}
        selectedAnswers={selectedAnswers}
        hasVideo={hasVideo}
        playYoutubeVideo={playYoutubeVideo}
        retryQuiz={retryQuiz}
      />
    )
  }
}

export default QuizResults
