import { MdHistory, MdLoop } from 'react-icons/md'

const NOTIFICATION_PREFERENCE_ONGOING = 'advert-collection-ongoing'
const NOTIFICATION_PREFERENCE_ADHOC = 'advert-collection-adhoc'

const getCardTitles = (t) => {
  return {
    [NOTIFICATION_PREFERENCE_ONGOING]: t(
      'notification-settings.setting-name.advert-collection-ongoing',
    ),
    [NOTIFICATION_PREFERENCE_ADHOC]: t(
      'notification-settings.setting-name.advert-collection-adhoc',
    ),
  }
}

const getCardIcon = (settingName) => {
  switch (settingName) {
    case NOTIFICATION_PREFERENCE_ONGOING:
      return MdLoop
    case NOTIFICATION_PREFERENCE_ADHOC:
      return MdHistory
  }
}

const getCardIconClass = (settingName) => {
  switch (settingName) {
    case NOTIFICATION_PREFERENCE_ONGOING:
      return 'ongoing'
    case NOTIFICATION_PREFERENCE_ADHOC:
      return 'adhoc'
  }
}

const filterNotificationPreferences = (preferences) => {
  return preferences.filter((preference) => preference.name.includes('advert-collection'))
}

const getOptions = (t) => [
  {
    value: 'once-hourly',
    label: t('notification-settings.setting-option.once-hourly'),
  },
  {
    value: 'once-daily',
    label: t('notification-settings.setting-option.once-daily'),
  },
  {
    value: 'twice-daily',
    label: t('notification-settings.setting-option.twice-daily'),
  },
  {
    value: 'never',
    label: t('notification-settings.setting-option.never'),
  },
]

export {
  getCardIcon,
  getCardIconClass,
  filterNotificationPreferences,
  getOptions,
  getCardTitles,
  NOTIFICATION_PREFERENCE_ONGOING,
  NOTIFICATION_PREFERENCE_ADHOC,
}
