import {
  COLLECTION_INDUCTIONS_FETCH,
  COLLECTION_INDUCTIONS_FETCH_SUCCESS,
} from '@redux/actionTypes'
import { cleanInductions } from '@redux/utilities/handleInductionData'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { CANCELED_ERROR, loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:fetchCollectionInductions')

const fetchCollectionInductions = (collectionId) => {
  return (dispatch) => {
    return new Promise<void>((resolve, reject) => {
      const failedFetch = (error) => {
        if (error.name === CANCELED_ERROR) {
          debug(`Cancelled duplicate request`)
          return
        }

        const reason = loggableError(error)
        debug(`Failed fetching inductions for collection ${collectionId}`, reason)
        reject(reason)
      }

      dispatch({
        type: COLLECTION_INDUCTIONS_FETCH,
      })

      return axios
        .get(`/api/v1/collections/${collectionId}/inductions`)
        .then((response) => {
          const { data } = response

          dispatch({
            type: COLLECTION_INDUCTIONS_FETCH_SUCCESS,
            payload: {
              collectionId,
              inductions: cleanInductions(data),
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}

export default fetchCollectionInductions
