import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdLockOutline } from 'react-icons/md'
import { INFO } from 'config/alert'
import { COLLECTION_TYPES } from 'config/collections'
import { modalKeys } from 'config/modals'
import { formatDateToDayAndMonth } from 'helpers/time'
import { useModal } from 'hooks/application'
import { useIsFeatureFlagEnabled } from 'hooks/application'
import { useCollection } from 'hooks/collections'
import ClaimCollectionModal from 'components/AvailableCollections/ClaimCollection/ClaimCollectionModal'
import ClaimCollectionModalLegacy from 'components/AvailableCollections/ClaimCollection/ClaimCollectionModalLegacy'
import ClaimCollectionSuccessModal from 'components/AvailableCollections/ClaimCollection/ClaimCollectionSuccessModal'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import styles from './ClaimCollection.module.scss'
import useHasCollectionOnSameDay from './useHasCollectionOnSameDay'

type ClaimButtonType = {
  onClick: () => void
  label: string
  secondary?: boolean
  showLock?: boolean
}

const renderClaimButton = ({
  onClick,
  label,
  secondary = false,
  showLock = false,
}: ClaimButtonType) => (
  <Button
    onClick={onClick}
    leftContent={showLock ? <MdLockOutline data-testid="lockIcon" /> : null}
    secondary={secondary}
  >
    {label}
  </Button>
)

type ClaimCollectionType = {
  collectionId: number
  requiredInductions: { isComplete: boolean }[]
}

const ClaimCollection = ({ collectionId, requiredInductions }: ClaimCollectionType) => {
  const { t } = useTranslation()
  const isSimpleSlotLimitsFeatureEnabled = useIsFeatureFlagEnabled('simpleSlotLimits')
  const {
    data: { derestricted, nextCollection, type },
  } = useCollection({ collectionId })
  const hasCollectionOnSameDay = useHasCollectionOnSameDay(collectionId)
  const { openModal: openClaimCollectionModal, isOpen: isClaimCollectionModalOpen } = useModal(
    modalKeys.CLAIM_COLLECTION,
    {
      trackingProperties: {
        spaceCheckboxDisplayed: hasCollectionOnSameDay,
        derestricted,
      },
    },
  )
  const { isOpen: isClaimCollectionSuccessModalOpen } = useModal(modalKeys.CLAIM_COLLECTION_SUCCESS)
  const [collectionClaimAttempted, setCollectionClaimAttempted] = useState(false)
  const areSomeInductionsIncomplete = useMemo(
    () => requiredInductions.some((induction) => induction.isComplete === false),
    [requiredInductions],
  )

  const handleClaimAttempt = () => setCollectionClaimAttempted(true)

  const claimLabel =
    type === COLLECTION_TYPES.ONGOING
      ? t('collection-slots.show.claim.button-ongoing')
      : t('collection-slots.show.claim.button')

  const claimAsOneOffLabel = t('collection-slots.show.claim.button-oneoff', {
    date: formatDateToDayAndMonth(nextCollection),
  })

  return (
    <>
      {areSomeInductionsIncomplete ? (
        <>
          <div className={styles.inlineActions}>
            {type === COLLECTION_TYPES.ONGOING &&
              renderClaimButton({
                onClick: handleClaimAttempt,
                label: claimAsOneOffLabel,
                secondary: true,
                showLock: true,
              })}
            {renderClaimButton({
              onClick: handleClaimAttempt,
              label: claimLabel,
              showLock: true,
            })}
          </div>
          {collectionClaimAttempted === true && (
            <div className="inductions-warning">
              <Alert variant={INFO}>{t('collections.available.inductions-needed-message')}</Alert>
            </div>
          )}
        </>
      ) : (
        <div className={styles.inlineActions}>
          {type === COLLECTION_TYPES.ONGOING &&
            renderClaimButton({
              onClick: () => openClaimCollectionModal({ nextIterationOnly: true }),
              label: claimAsOneOffLabel,
              secondary: true,
            })}

          {renderClaimButton({
            onClick: () => openClaimCollectionModal({ nextIterationOnly: false }),
            label: claimLabel,
          })}
        </div>
      )}
      {isClaimCollectionModalOpen && (
        <>
          {isSimpleSlotLimitsFeatureEnabled ? (
            <ClaimCollectionModal collectionId={collectionId} />
          ) : (
            <ClaimCollectionModalLegacy
              collectionId={collectionId}
              hasCollectionOnSameDay={hasCollectionOnSameDay}
            />
          )}
        </>
      )}
      {isClaimCollectionSuccessModalOpen && <ClaimCollectionSuccessModal />}
    </>
  )
}

export default ClaimCollection
