import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import claimCollection from '@redux/thunks/collections/claimCollection'
import claimNextIteration from '@redux/thunks/collections/claimNextIteration'
import { trackEvent } from 'services/Analytics'
import { COLLECTION_TYPES } from 'config/collections'
import { modalKeys, modalTrackingKeys } from 'config/modals'
import Debug from 'helpers/debug'
import { translateApiErrors } from 'helpers/errors'
import { formatDateToTime24, formatShortDateAndTime24 } from 'helpers/time'
import { useModal } from 'hooks/application'
import { useCollection } from 'hooks/collections'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import {
  ConfirmationModal,
  ModalFooter,
  ModalTitle,
} from 'components/common/Modal/ConfirmationModal'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import styles from './ClaimCollectionModal.module.scss'
import SameDayCollectionInfo from './SameDayCollectionInfo'
import useCheckCollectionClaimability from './useCheckCollectionClaimability'

const debug = Debug('components:collections:available-collections:claimcollectionmodal')

type ClaimCollectionModalType = {
  collectionId: number
}
const ClaimCollectionModal = ({ collectionId }: ClaimCollectionModalType) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    closeModal: closeCurrentModal,
    data: { nextIterationOnly },
  } = useModal(modalKeys.CLAIM_COLLECTION)
  const { openModal: openClaimCollectionSuccessModal } = useModal(
    modalKeys.CLAIM_COLLECTION_SUCCESS,
  )
  const [confirmSpace, setConfirmSpace] = useState(false)

  const errors = useAppSelector((state) => state.collections.errors)

  const { data: collection } = useCollection({
    collectionId,
  })
  const store = collection?.store
  const businessName = collection?.business.name
  const nextCollectionDate = collection?.nextCollection
  const timezone = store?.timezone

  const {
    sameDayCollectionDate,
    sameDayCollectionCount,
    isLoading: isCheckingCollectionClaimability,
  } = useCheckCollectionClaimability({ collectionId, nextIterationOnly })

  const collectionType = nextIterationOnly === true ? COLLECTION_TYPES.ADHOC : collection?.type
  const handleClaimCollectionClick = async () => {
    const action = nextIterationOnly === true ? claimNextIteration : claimCollection
    const hasCollectionOnSameDay = sameDayCollectionCount > 0
    const response: any = await dispatch(action(collectionId, hasCollectionOnSameDay)).catch(debug)

    if (response?.success === true) {
      closeCurrentModal()
      openClaimCollectionSuccessModal({ collectionId: response.collectionId })
    }
  }

  useEffect(() => {
    if (errors !== undefined && errors.length !== 0) {
      trackEvent('COLLECTION_CLAIM_UNSUCCESSFUL', {
        collection_id: collectionId,
        business: businessName,
        nextAvailableSlotTime: formatDateToTime24(nextCollectionDate, timezone),
        nextAvailableSlotDate: formatShortDateAndTime24(nextCollectionDate, timezone),
        error_keys: errors,
      })
    }
  }, [errors])

  return (
    <ConfirmationModal modalKey={modalKeys.CLAIM_COLLECTION}>
      <div className={styles.modalContent}>
        <section>
          <ModalTitle>{t('collection-slots.claim.confirmation-modal.header')}</ModalTitle>
          <p>
            {t('collection-slots.claim.confirmation-modal.description', {
              collectionType,
            })}
          </p>
          <p>
            {collectionType === COLLECTION_TYPES.ONGOING
              ? t('collection-slots.claim.confirmation-modal.instruction_ongoing')
              : t('collection-slots.claim.confirmation-modal.instruction_other')}
          </p>
          {errors && (
            <Alert variant="error">
              <SetInnerHtmlSafe>{translateApiErrors({ t, errors })}</SetInnerHtmlSafe>
            </Alert>
          )}
        </section>

        <SameDayCollectionInfo
          confirmSpace={confirmSpace}
          setConfirmSpace={setConfirmSpace}
          sameDayCollectionCount={sameDayCollectionCount}
          date={sameDayCollectionDate}
          storeTimezone={timezone}
        />
      </div>

      <ModalFooter>
        <Button
          data-tracking={modalTrackingKeys.reject}
          onClick={() => closeCurrentModal()}
          secondary
        >
          {t('collection-slots.claim.confirmation-modal.cancel-button')}
        </Button>
        {sameDayCollectionCount < 3 && (
          <Button
            data-tracking={modalTrackingKeys.confirm}
            onClick={handleClaimCollectionClick}
            disabled={
              isCheckingCollectionClaimability ||
              (sameDayCollectionCount > 0 && confirmSpace === false)
            }
          >
            {t('collection-slots.claim.confirmation-modal.confirm-button')}
          </Button>
        )}
      </ModalFooter>
    </ConfirmationModal>
  )
}

export default ClaimCollectionModal
