import { HelpScout, LiveChatLoaderProvider } from 'react-live-chat-loader'
import { getHelpScoutProviderKey } from 'helpers/helpScoutBeacon'
import styles from './HelpScoutBeacon.module.scss'

const HelpScoutBeacon = () => {
  return (
    <LiveChatLoaderProvider
      providerKey={getHelpScoutProviderKey()}
      provider="helpScout"
    >
      <HelpScout containerClass={styles.helpscout} />
    </LiveChatLoaderProvider>
  )
}

export default HelpScoutBeacon
