import clsx from 'clsx'

type Props = {
  checked: boolean
  className?: string
  label: string
  id?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  inputRef?: any
  testId?: string
}

const Checkbox = ({ checked, className, label, id, onChange, onBlur, inputRef, testId }: Props) => {
  return (
    <label
      className={clsx('checkbox', className)}
      data-testid={testId}
      htmlFor={id}
    >
      <input
        type="checkbox"
        className="filled-in"
        id={id}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        ref={inputRef}
      />
      <span className="checkbox-label">{label}</span>
    </label>
  )
}

export default Checkbox
