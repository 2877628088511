import { createAsyncThunk } from '@reduxjs/toolkit'
import { cleanQuestions } from '@redux/utilities/handleInductionData'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:inductions:fetchInduction')

const fetchInduction = createAsyncThunk(
  'inductions/fetchInduction',
  async (inductionId: string, { rejectWithValue, fulfillWithValue }) => {
    return axios
      .get(`/api/v1/inductions/${inductionId}`)
      .then((response) => {
        const data = response.data

        const { id, title, description, content, questions, is_complete: isComplete } = data

        return fulfillWithValue({
          id,
          title,
          description,
          content,
          isComplete,
          questions: cleanQuestions(questions),
        })
      })
      .catch((error) => {
        debug(`Failed fetching induction ${inductionId}`, loggableError(error))
        return rejectWithValue(error.response.status)
      })
  },
)

export default fetchInduction
