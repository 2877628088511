import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'
import { getCurrentUserId } from 'helpers/user'

const debug = Debug('redux:thunks:preferences:fetchUserPreferences')

const fetchUserPreferences = createAsyncThunk(
  'preferences/fetchUserPreferences',
  (_, { rejectWithValue, fulfillWithValue }) => {
    return axios
      .get(`/api/v1/users/${getCurrentUserId()}/preferences`)
      .then((response) => {
        const userPreferences = response.data

        return fulfillWithValue(userPreferences)
      })
      .catch((error) => {
        debug(`Failed fetching user preferences`, loggableError(error))
        return rejectWithValue(error.response.status)
      })
  },
)

export default fetchUserPreferences
