import { useDispatch } from 'react-redux'
import { USER_DATA_HYDRATE } from '@redux/actionTypes'

const UserDataProvider = ({ children, userId, userRoles, userIsVerified, userRating }) => {
  const dispatch = useDispatch()

  if (userId && userRoles) {
    dispatch({
      type: USER_DATA_HYDRATE,
      payload: {
        userId,
        userRoles,
        userIsVerified,
        userRating,
      },
    })
  }

  return children
}

export default UserDataProvider
