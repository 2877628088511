import { useTranslation } from 'react-i18next'
import {
  MdCheckCircleOutline,
  MdEvent,
  MdHistory,
  MdLoop,
  MdOutlineRecommend,
} from 'react-icons/md'
import clsx from 'clsx'
import { COLLECTION_TYPES } from 'config/collections'
import { DISTANCE_PREFERENCE_KEY } from 'config/user'
import {
  getCollectionFrequencyTranslation,
  getCollectionTypeTranslation,
} from 'helpers/collections'
import { formatLongDate } from 'helpers/time'
import { useIsMobile } from 'hooks/application'
import { usePreference } from 'hooks/preferences'
import Distance from 'components/AvailableCollections/Distance'
import Label from 'components/common/Label'
import styles from './AvailableCollectionInformation.module.scss'
import InductionProgress from './InductionProgress'
import InformationIcon from './InformationIcon'
import Location from './Location'

export const BUSINESS_ICELAND = 'Iceland'
export const BUSINESS_TESCO = 'Tesco'

type Props = {
  type: string
  frequency?: string
  nextCollection: string
  storeAddress: StoreAddressType
  storeLocation: {
    latitude: number
    longitude: number
  }
  homeLocation: {
    latitude: number
    longitude: number
  }
  storeTimezone?: string
  completedInductionsCount?: number
  inductionsCount?: number
  businessName?: string
  className?: string
  donationConfirmedAt?: string
}

const AvailableCollectionInformation = ({
  type,
  homeLocation,
  storeLocation,
  frequency,
  nextCollection,
  storeAddress,
  storeTimezone,
  completedInductionsCount,
  inductionsCount,
  businessName,
  className,
  donationConfirmedAt,
}: Props) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const { preference: distance } = usePreference(DISTANCE_PREFERENCE_KEY)
  const distanceUnits = distance.value

  const getTypeIcon = () => {
    if (type === COLLECTION_TYPES.ONGOING) {
      return (
        <InformationIcon
          Icon={MdLoop}
          collectionType={type}
          testId="loopIcon"
        />
      )
    } else if (type === COLLECTION_TYPES.ADHOC || type === COLLECTION_TYPES.TEMPORARY) {
      return (
        <InformationIcon
          Icon={MdHistory}
          collectionType={type}
          testId="historyIcon"
        />
      )
    }
    return ''
  }

  const getDateText = () => {
    if (type === COLLECTION_TYPES.ONGOING) {
      return <div>{t('collection_slots.partials.slot.next_collection')}</div>
    } else if (type === COLLECTION_TYPES.ADHOC) {
      return <div>{t('collection_slots.partials.slot.last_collection')}</div>
    }
    return null
  }

  const isCollectionTesco = businessName === BUSINESS_TESCO

  const isIdealFirstCollection =
    type === COLLECTION_TYPES.ADHOC || businessName === BUSINESS_ICELAND

  return (
    <div data-testid={`collection-type-${type}`}>
      {donationConfirmedAt !== null && (
        <Label
          icon={<MdCheckCircleOutline />}
          text={t('common.label.food-confirmed')}
          variant="green"
          className={styles.foodConfirmedLabel}
        />
      )}
      <div
        className={clsx(styles.availableCollectionInformation, {
          [className]: className !== undefined,
        })}
      >
        <div>
          <div className={styles.collectionLocationDetails}>
            <Location
              type={type}
              storeAddress={storeAddress}
            />
            <Distance
              baseLocation={homeLocation}
              destinationLocation={storeLocation}
              units={distanceUnits}
            />
          </div>

          {frequency && (
            <div className={clsx(styles.collectionTypeAndFrequency, styles[type])}>
              <span>{getTypeIcon()}</span>
              <div>
                <div>
                  <strong>{getCollectionTypeTranslation({ t, type })}</strong>
                </div>
                {type === COLLECTION_TYPES.ONGOING && (
                  <div
                    className={styles.frequency}
                  >{`(${getCollectionFrequencyTranslation({ t, frequency })})`}</div>
                )}
              </div>
            </div>
          )}
          {isMobile === false && isIdealFirstCollection === true && isCollectionTesco !== true && (
            <Label
              icon={<MdOutlineRecommend />}
              text={t('common.label.ideal-for-new')}
            />
          )}
        </div>
        <div>
          <div className={clsx(styles.info, styles.nextCollection)}>
            <span>
              <InformationIcon
                testId="eventIcon"
                Icon={MdEvent}
                collectionType={type}
              />
            </span>
            <div>
              <span className={styles.nextCollectionText}>{getDateText()}</span>
              <strong data-testid="available-collection-date">
                {formatLongDate(nextCollection, storeTimezone)}
              </strong>
            </div>
          </div>
          {inductionsCount > 0 && (
            <InductionProgress
              completedInductionsCount={completedInductionsCount}
              inductionsCount={inductionsCount}
              collectionType={type}
            />
          )}
          {isMobile === true && isIdealFirstCollection === true && (
            <Label
              icon={<MdOutlineRecommend />}
              text={t('common.label.ideal-for-new')}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AvailableCollectionInformation
