import { useIsMobile } from 'hooks/application'
import InductionCard from 'components/Inductions/InductionCard'
import { Grid, Stack } from 'components/layout/primitives'

type Props = {
  title?: string
  description?: string
  inductions: any[]
}

const InductionsList = ({ title, inductions, description }: Props) => {
  const isMobile = useIsMobile()

  return (
    <Stack gap="1rem">
      {inductions.length > 0 && (
        <>
          {title !== undefined && <h2>{title}</h2>}
          {description !== undefined && <p>{description}</p>}
          <Grid
            columns={isMobile === true ? 1 : 3}
            gap="1rem"
          >
            {inductions.map((induction) => (
              <InductionCard
                key={induction.id}
                title={induction.title}
                inductionId={induction.id}
                description={induction.description}
                isComplete={induction.isComplete}
              />
            ))}
          </Grid>
        </>
      )}
    </Stack>
  )
}

export default InductionsList
