/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { trackEvent } from 'services/Analytics'
import { useModalLockBodyScroll } from 'hooks/application'
import styles from './GenericModal.module.scss'

type Props = {
  closeModal?: () => void
  id?: string
  children?: React.ReactNode
  className?: string
  modalKey?: string
}

const GenericModal = ({ closeModal, id, className, children, modalKey }: Props) => {
  useModalLockBodyScroll()
  const modalRef = useRef<HTMLInputElement>()
  const allFocusableElementsRef = useRef<NodeListOf<HTMLInputElement>>()

  useEffect(() => {
    allFocusableElementsRef.current = modalRef?.current.querySelectorAll(
      `button:not([disabled]), [href], input:not([disabled]), 
    select, textarea, [tabindex]:not([tabindex="-1"])`,
    )
    allFocusableElementsRef.current[0]?.focus()
  }, [modalRef.current])

  useEffect(() => {
    const firstFocusableElement = allFocusableElementsRef.current[0]
    if (firstFocusableElement !== undefined) {
      firstFocusableElement.focus()
    }
  }, [allFocusableElementsRef.current])

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      allFocusableElementsRef.current = modalRef?.current.querySelectorAll(
        `button:not([disabled]), [href], input:not([disabled]), 
      select, textarea, [tabindex]:not([tabindex="-1"])`,
      )

      const allFocusableElements = allFocusableElementsRef.current
      const first = allFocusableElements[0]
      const last = allFocusableElements[allFocusableElements.length - 1]
      if (event.shiftKey) {
        if (document.activeElement === first) {
          event.preventDefault()
          last.focus()
        }
      } else {
        if (document.activeElement === last) {
          event.preventDefault()
          first.focus()
        }
      }
    }
  }

  const handleOverlayClick = () => {
    if (modalKey) {
      trackEvent('MODAL_DISMISS_OVERLAY', { modalKey })
    }
    closeModal()
  }

  return (
    <div
      className={styles.modalContainer}
      onKeyDown={handleKeyDown}
    >
      <div
        className="overlay"
        onClick={handleOverlayClick}
        data-testid="modal-overlay"
      />
      <div
        id={id}
        className={clsx(styles.genericModal, className)}
        ref={modalRef}
        data-testid="generic-modal"
        role="dialog"
      >
        {children}
      </div>
    </div>
  )
}

export default GenericModal
