import { FEATURE_FLAGS_FETCH_SUCCESS } from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:application:fetchFeatureFlags')

export default () => {
  return (dispatch) => {
    const failedFetch = (error) => {
      const reason = loggableError(error)
      debug(`Failed fetching feature flags`, reason)
    }

    return axios
      .get('/api/v1/features/enabled')
      .then((response) => {
        dispatch({
          type: FEATURE_FLAGS_FETCH_SUCCESS,
          payload: response.data,
        })
      })
      .catch(failedFetch)
  }
}
