import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { motion, useAnimation, useInView } from 'framer-motion'
import { useAppSelector } from '@redux/hooks'
import { AVAILABLE_COLLECTIONS_URL } from 'config/routes'
import { getMapUrl, getSignedStaticMapUrl } from 'helpers/map'
import { formatDateToTime24 } from 'helpers/time'
import { useIsFeatureFlagEnabled } from 'hooks/application'
import { useCollection } from 'hooks/collections'
import Late_Badge from 'images/Late_Badge.svg'
import AvailableCollectionInformation from 'components/AvailableCollections/AvailableCollectionInformation'
import UnlimitedSlotLabel from 'components/Collections/UnlimitedSlotLabel'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { Spacer } from 'components/layout/primitives'
import styles from './AvailableCollectionsListCard.module.scss'

type AvailableCollectionsListCardType = {
  collectionId: number
  storeName: string
  businessName: string
  storeAddress: StoreAddressType
  type: string
  storeLocation: {
    latitude: number
    longitude: number
  }
  nextCollection: string
  collectionNotes?: string
  storeTimezone?: string
  donationConfirmedAt?: string
}

const AvailableCollectionsListCard = ({
  collectionId,
  type,
  storeName,
  businessName,
  storeAddress,
  storeLocation,
  nextCollection,
  collectionNotes,
  storeLocation: { latitude, longitude },
  storeTimezone,
  donationConfirmedAt,
}: AvailableCollectionsListCardType) => {
  const { t } = useTranslation()
  const [mapUrl, setMapUrl] = useState()
  const ref = useRef(null)
  const isInView = useInView(ref)
  const animation = useAnimation()
  const isSimpleSlotLimitsFeatureEnabled = useIsFeatureFlagEnabled('simpleSlotLimits')

  const { data: collection } = useCollection({
    collectionId,
    dataToInclude: { inductions: true },
  })

  const homeLocation = useAppSelector((state) => state.users.currentUser?.location, shallowEqual)
  const mapLinkUrl = getMapUrl(storeLocation)
  const collectionTime = formatDateToTime24(nextCollection, storeTimezone)

  useLayoutEffect(() => {
    if (isInView === true) {
      animation.start({
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          duration: 0.4,
          bounce: 0.15,
        },
      })
    }
  }, [animation, isInView])

  useEffect(() => {
    const getMapUrl = async () => {
      const mapUrl = await getSignedStaticMapUrl({ latitude, longitude, width: 520, height: 157 })
      setMapUrl(mapUrl)
    }
    if (isInView === true) {
      getMapUrl()
    }
  }, [isInView])

  const displayUnlimitedSlotLabel =
    !isSimpleSlotLimitsFeatureEnabled && collection?.derestricted === true

  return (
    <motion.div
      className={`available-collections-list-card ${type} card`}
      initial={{ opacity: 0, y: 50 }}
      exit={{ opacity: 0 }}
      animate={animation}
      ref={ref}
    >
      <div className="card-content">
        <div>
          {displayUnlimitedSlotLabel && (
            <>
              <UnlimitedSlotLabel />
              <Spacer x="1.25rem" />
            </>
          )}
          <h3 className="store-name">{`${businessName} |`}</h3>
          <h3 className="business-name">{storeName}</h3>
          <AvailableCollectionInformation
            homeLocation={homeLocation}
            storeLocation={storeLocation}
            storeAddress={storeAddress}
            nextCollection={nextCollection}
            type={type}
            storeTimezone={storeTimezone}
            businessName={businessName}
            donationConfirmedAt={donationConfirmedAt}
          />
          {collectionTime >= '22:00' && (
            <>
              <div className={styles.lateBadge}>
                <img
                  src={Late_Badge}
                  alt=""
                />
                <span className={styles.label}>{t('collections.available.late-collection')}</span>
              </div>
              <Spacer x="1.25rem" />
            </>
          )}
          {collectionNotes !== undefined && (
            <div className="alert-info-message">
              <Alert variant="info">
                <SetInnerHtmlSafe>{collectionNotes}</SetInnerHtmlSafe>
              </Alert>
            </div>
          )}
        </div>
        <div>
          <div className="bottom-map hide-on-small-only">
            <a
              href={mapLinkUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src={mapUrl}
                data-testid="map-image"
                loading="lazy"
              />
            </a>
          </div>
          <Link to={`${AVAILABLE_COLLECTIONS_URL}/${collectionId}`}>
            <Button
              testId={`view-details-button-${type}`}
              buttonSize="fullWidthButton"
            >
              {t('collection_slots.partials.slot.details-button')}
            </Button>
          </Link>
        </div>
      </div>
    </motion.div>
  )
}

export default AvailableCollectionsListCard
