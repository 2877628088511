import {
  COLLECTION_PAUSE_SET_OPTION,
  COLLECTION_SKIP_SET_OPTION,
  COLLECTION_SWAP_SET_OPTION,
  COLLECTION_UNASSIGN_SET_OPTION,
} from '@redux/actionTypes'
import { COLLECTION_ACTIONS } from 'helpers/collections'
import Debug from 'helpers/debug'

const { UNASSIGN, SKIP, PAUSE, SWAP } = COLLECTION_ACTIONS

type setSelectedOptionsArgs = {
  action?: string
  storeId?: number
  collectionId?: number
  selectedDate?: string
  selectedIncrement?: string
  modalMode?: string
  swapForCollectionId?: number
}

const setSelectedOptions = ({
  action,
  storeId,
  collectionId,
  selectedDate,
  selectedIncrement,
  modalMode,
  swapForCollectionId,
}: setSelectedOptionsArgs) => {
  const debug = Debug(`javascript:redux:thunks:collections:set-${action}-options`)

  let actionToSend

  switch (action) {
    case UNASSIGN:
      actionToSend = COLLECTION_UNASSIGN_SET_OPTION
      break
    case SKIP:
      actionToSend = COLLECTION_SKIP_SET_OPTION
      break
    case PAUSE:
      actionToSend = COLLECTION_PAUSE_SET_OPTION
      break
    case SWAP:
      actionToSend = COLLECTION_SWAP_SET_OPTION
      break
    default:
      actionToSend = undefined
      debug('Invalid collection action')
      break
  }

  return (dispatch) => {
    dispatch({
      type: actionToSend,
      payload: {
        storeId,
        collectionId,
        selectedDate,
        selectedIncrement: Number(selectedIncrement),
        modalMode,
        swapForCollectionId,
      },
    })
  }
}

export default setSelectedOptions
