import { MODAL_OPEN } from '@redux/actionTypes'

export default ({ key, data }) => {
  return (dispatch) => {
    dispatch({
      type: MODAL_OPEN,
      payload: { key, data },
    })
  }
}
