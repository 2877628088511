import { useTranslation } from 'react-i18next'
import styles from './MakeADifference.module.scss'

const MakeADifference = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.makeADifference}>
      <h3>{t('landing-page.make-a-difference.title')}</h3>
      <p>{t('landing-page.make-a-difference.information1')}</p>
      <p>{t('landing-page.make-a-difference.information2')}</p>
      <p>{t('landing-page.make-a-difference.information3')}</p>
    </div>
  )
}

export default MakeADifference
