import i18next from 'i18next'
import { DateTime } from 'luxon'

export const SECOND_IN_MILLISECONDS = 1000
export const HOUR_IN_MILLISECONDS = 3600 * SECOND_IN_MILLISECONDS

/**
 * @param {string} date ISO Date to use for calculation
 * @returns string like "In 5 hours", "In 3 days" or "In 2 weeks" using luxon
 */
const calculateRelativeTimeFromNow = (date) => {
  const dateToCompare = DateTime.fromISO(date)
  const dateNormalisedToMidnight = dateToCompare.set({ hours: 0, minutes: 0, seconds: 0 })
  const today = DateTime.now().toISODate()

  if (dateToCompare.toISODate() === today) {
    return dateToCompare.toRelative({
      locale: i18next.language,
    })
  }

  return dateNormalisedToMidnight.toRelative({
    locale: i18next.language,
    padding: 24 * HOUR_IN_MILLISECONDS,
  })
}

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "Wednesday, 15:00", "Monday, 18:30" or "miércoles, 19:00"
 */
const formatDateToDayAndTime24 = (date: string, timezone?: string) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat(
      'EEEE, HH:mm',
    )
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('EEEE, HH:mm')
  }
}

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "Monday, 21 Mar" or "miércoles, 12 dic"
 */
const formatDateToDayOfWeekAndDate = (date, timezone) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat(
      'EEEE, d MMM',
    )
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('EEEE, d MMM')
  }
}

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "17 Dec, 13:00" or "17 dic, 13:00"
 */
const formatDateToDayMonthAndTime = (date, timezone?) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat(
      'dd MMM, HH:mm',
    )
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('dd MMM, HH:mm')
  }
}

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "15:00" or "18:30"
 */
const formatDateToTime24 = (date: string, timezone?: string) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat('HH:mm')
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('HH:mm')
  }
}

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "Wed, 14 Dec 2022, 19:00"
 */
const formatShortDateAndTime24 = (date: string, timezone?: string) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat('EEE, ff')
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('EEE, ff')
  }
}

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "Friday, 14 Sep, 19:00"
 */
const formatLongDate = (date: string, timezone?: string) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat(
      'EEEE, dd MMM, HH:mm',
    )
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('EEEE, dd MMM, HH:mm')
  }
}

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "11 Sept"
 */
const formatDateToDayAndMonth = (date: string, timezone?: string) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat('d MMM')
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('d MMM')
  }
}

const convertSecondsToMinutes = (seconds) => {
  const minutesCount = seconds / 60

  if (minutesCount < 1) {
    return 1
  }

  return Math.round(minutesCount)
}

export {
  calculateRelativeTimeFromNow,
  formatDateToDayAndTime24,
  formatDateToDayOfWeekAndDate,
  formatDateToDayMonthAndTime,
  formatDateToTime24,
  formatShortDateAndTime24,
  formatDateToDayAndMonth,
  formatLongDate,
  convertSecondsToMinutes,
}
