import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useAppSelector } from '@redux/hooks'

type Props = {
  nextCollection: string
  userId?: number
  groupName: string
  value?: number
  isChecked: boolean
  isDisabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  timezone?: string
}

const TargetCollectionSelection = ({
  userId,
  nextCollection,
  value,
  groupName,
  isChecked,
  onChange,
  isDisabled,
  timezone,
}: Props) => {
  const { t, i18n } = useTranslation()
  const userName = useAppSelector((state) => state.users.items[userId]?.name)
  const nextCollectionDate = DateTime.fromISO(nextCollection)

  return (
    <li>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        <input
          className="with-gap"
          name={groupName}
          type="radio"
          value={value}
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('your-collections.page.show.swap-modal.choices-modal.swap-for-slot', {
              name: userName,
              date: DateTime.fromISO(nextCollectionDate, {
                zone: timezone,
                locale: i18n.language,
              }).toFormat('EEE, dd MMM HH:mm'),
            }),
          }}
        />
      </label>
    </li>
  )
}

export default TargetCollectionSelection
