import type { CSSProperties } from 'react'
import clsx from 'clsx'
import styles from './Stack.module.scss'

type Props = {
  className?: string
  children: React.ReactNode
  gap?: string
}

const Stack = ({ className, children, gap = '1rem' }: Props) => {
  const style = { '--stack-vertical-gap': gap } as CSSProperties

  return (
    <div
      className={clsx(styles.root, className)}
      style={style}
    >
      {children}
    </div>
  )
}

export default Stack
