import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Button from 'components/common/Button'
import Card from 'components/common/Cards/Card'
import styles from './CardWithButton.module.scss'

type Props = {
  title: string
  subtitle?: string
  description: string
  description2?: string
  buttonText: string
  buttonLink: string
  icon?: string
  buttonSecondary?: boolean
  className?: string
  headingIcon?: React.ReactNode
  onClick?: () => void
}

const CardWithButton = ({
  title,
  subtitle,
  description,
  description2,
  buttonText,
  buttonLink,
  icon,
  buttonSecondary = false,
  className,
  headingIcon,
  onClick,
}: Props) => {
  return (
    <Card className={clsx(styles.cardWithButton, className)}>
      <div className={styles.cardBody}>
        <div className={styles.descriptionHeader}>
          {headingIcon !== undefined && <span className={styles.smallIcon}>{headingIcon}</span>}
          <h3 className={styles.title}>{title}</h3>
        </div>
        {subtitle !== undefined && <strong className={styles.subtitle}>{subtitle}</strong>}
        <div className={styles.descriptionContainer}>
          <div>
            <p className="description-1">{description}</p>
            {description2 !== undefined && <p className={styles.description2}>{description2}</p>}
          </div>
          {icon !== undefined && (
            <div className={styles.cardIcon}>
              <img
                alt=""
                src={icon}
              />
            </div>
          )}
        </div>
      </div>
      <Link
        data-testid="cardWithButtonLink"
        to={buttonLink}
      >
        <Button
          secondary={buttonSecondary}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Link>
    </Card>
  )
}

export default CardWithButton
