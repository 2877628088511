import { COLLECTIONS_FETCH_START, COLLECTIONS_FETCH_SUCCESS } from '@redux/actionTypes'
import { cleanCollections } from '@redux/utilities/handleCollectionData'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { CANCELED_ERROR, loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:fetchAvailableCollections')

export default () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const failedFetch = (error) => {
        if (error.name === CANCELED_ERROR) {
          debug(`Cancelled duplicate request`)
          return
        }

        const reason = loggableError(error)
        debug(`Failed fetching available collections`, reason)
        reject(reason)
      }

      dispatch({
        type: COLLECTIONS_FETCH_START,
      })

      return axios
        .get('/api/v1/collections?available=true')
        .then((response) => {
          const { data } = response
          const collections = cleanCollections(data, { available: true })
          dispatch({
            type: COLLECTIONS_FETCH_SUCCESS,
            payload: {
              collections,
            },
          })
          resolve(collections)
        })
        .catch(failedFetch)
    })
  }
}
