import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'
import clsx from 'clsx'
import { useAppSelector } from '@redux/hooks'
import { sortRotaItemsByDate } from 'helpers/collections'
import RotaItem from 'components/common/StoreRota/RotaItem'
import styles from './Rota.module.scss'

type Props = {
  storeId: number
  className?: string
  timezone?: string
}

const Rota = ({ storeId, className, timezone }: Props) => {
  const { t } = useTranslation()
  const storeCollectionIds = useAppSelector((state) => state.stores.items[storeId]?.collections)
  const storeCollections = useAppSelector((state) => state?.collections?.items)

  const sortedStoreCollections = useMemo(() => {
    if (storeCollectionIds !== undefined && storeCollections !== undefined) {
      return sortRotaItemsByDate(storeCollectionIds, storeCollections)
    }
  }, [storeCollectionIds, storeCollections])

  return (
    <div className={clsx(className, styles.rota)}>
      <h3>{t('your-collections.page.show.rota-key.page-title')}</h3>
      <div className={styles.wrapper}>
        <span className={styles.title}>{t('your-collections.page.show.rota-key.title')}</span>
        <div className={styles.keyWrapper}>
          <span className={clsx(styles.claimed, styles.key)}>
            <FaCheckCircle
              data-testid="claimedIcon"
              className={styles.icon}
            />
            {t('your-collections.page.show.rota-key.claimed')}
          </span>
          <span className={styles.key}>
            <FaRegCircle
              data-testid="unclaimedIcon"
              className={styles.icon}
            />
            {t('your-collections.page.show.rota-key.unclaimed')}
          </span>
        </div>
      </div>
      {sortedStoreCollections !== undefined &&
        sortedStoreCollections.map((item, index) => {
          const { nextCollection, userId, collectionState } = item
          return (
            <RotaItem
              key={index}
              nextCollection={nextCollection}
              userId={userId}
              collectionState={collectionState}
              timezone={timezone}
            />
          )
        })}
    </div>
  )
}

export default Rota
