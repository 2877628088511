import { memo, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchFeatureFlags from '@redux/thunks/application/fetchFeatureFlags'
import { trackEvent } from 'services/Analytics'
import Debug from 'helpers/debug'
import { formatDateToTime24, formatShortDateAndTime24 } from 'helpers/time'
import { getReportAnIssueUrl } from 'helpers/typeform'
import { getCurrentUserId } from 'helpers/user'
import { useIsFeatureFlagEnabled } from 'hooks/application'
import CollectionDates from 'components/Collections/CollectionDates'
import UnlimitedSlotLabel from 'components/Collections/UnlimitedSlotLabel'
import Button from 'components/common/Button'
import StoreInfo from 'components/common/StoreInfo'
import styles from './MyCollectionCard.module.scss'

const COLLECTIONS_PATH = '/my-collections'

const debug = Debug('components:collections:my-collection-card')

type Props = {
  collectionId: number
}

const MyCollectionCard = ({ collectionId }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    type: collectionType,
    frequency: collectionFrequency,
    storeId,
    nextCollection: nextCollectionDate,
    derestricted: isDerestrictedCollection,
  } = useAppSelector((state) => state.collections.items[collectionId] ?? {})
  const {
    name: storeName,
    businessId,
    location: storeLocation,
    timezone,
  } = useAppSelector((state) => state.stores.items[storeId] ?? {})
  const { name: businessName, logo: businessLogo } = useAppSelector(
    (state) => state.stores.businesses[businessId] ?? {},
  )
  const { url: businessLogoUrl } = businessLogo ?? {}
  const isSimpleSlotLimitsFeatureEnabled = useIsFeatureFlagEnabled('simpleSlotLimits')

  const trackReportAnIssueClickEvent = () => {
    trackEvent('COLLECTION_SOMETHING_WRONG', {
      business: businessName,
      type: collectionType,
      location: storeName,
      frequency: collectionFrequency,
      nextAvailableSlotTime: formatDateToTime24(nextCollectionDate, timezone),
      nextAvailableSlotDate: formatShortDateAndTime24(nextCollectionDate, timezone),
    })
  }

  const reportAnIssueUrl = useMemo(() => {
    return getReportAnIssueUrl({
      t,
      storeName,
      businessName,
      storeId,
      businessId,
      userId: getCurrentUserId(),
    })
  }, [storeName, businessName, storeId, businessId])

  useEffect(() => {
    dispatch(fetchFeatureFlags()).catch(debug)
  }, [])

  const displayUnlimitedSlotLabel = !isSimpleSlotLimitsFeatureEnabled && isDerestrictedCollection

  return (
    <div className={clsx('card', styles.root)}>
      <div className="card-content">
        {displayUnlimitedSlotLabel && <UnlimitedSlotLabel />}
        <div className={styles.content}>
          <div className={styles.collectionInfo}>
            {storeId && (
              <>
                <StoreInfo
                  logoUrl={businessLogoUrl}
                  location={storeLocation}
                  storeId={storeId}
                />
                <CollectionDates collectionId={collectionId} />
              </>
            )}
          </div>
          <div className={styles.buttons}>
            <Button href={`${COLLECTIONS_PATH}/${collectionId}`}>
              {t('your-collections.page.index.buttons.view-details')}
            </Button>
            <Button
              href={reportAnIssueUrl}
              onClick={trackReportAnIssueClickEvent}
              secondary
            >
              {t('your_collections.collection.something_wrong_text')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(MyCollectionCard)
