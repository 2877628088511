import React from 'react'
import type { CSSProperties, ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './Inline.module.scss'

type Props = {
  readonly children: ReactNode
  readonly gap: string
}

const Inline = ({ children, gap }: Props) => {
  const style = { '--gap': gap } as CSSProperties

  const childrenArray = React.Children.toArray(children)

  const childrenWithClass = childrenArray.map((child: ReactElement) => {
    return React.cloneElement(child, {
      className: clsx(child.props.className, styles.child),
    })
  })

  return (
    <div
      className={styles.root}
      style={style}
    >
      {childrenWithClass}
    </div>
  )
}

export default Inline
