import { TOGGLE_SIDEBAR } from '@redux/actionTypes'

const toggleSideBar = () => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SIDEBAR,
    })
  }
}
export default toggleSideBar
