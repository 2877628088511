import { isAndroid, isIOS } from 'react-device-detect'
import Env from 'helpers/environment'

const ROUTE_PROFILE = 'profile'

const androidProtocol = 'olioex'
const iosProtocol = 'olio'

export const getAppDeepLink = () => {
  let protocol = 'https'
  if (isAndroid) {
    protocol = androidProtocol
  } else if (isIOS) {
    protocol = iosProtocol
  }

  return `${protocol}://web.olioex.com`
}

export const getVerifyWithOlioLink = () => {
  return `${Env.PUBLIC_NATIVE_APP_HOST}${ROUTE_PROFILE}`
}
