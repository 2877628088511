import { ReactNode } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import clsx from 'clsx'
import Check from 'images/checkInCircleColour.svg'
import GenericModal from 'components/common/Modal/GenericModal'
import styles from './SuccessModal.module.scss'

type Props = {
  className?: string
  children: ReactNode
  onClose: () => void
  modalKey: string
}

const SuccessModal = ({ className, onClose, children, modalKey }: Props) => {
  return (
    <GenericModal
      closeModal={onClose}
      className={clsx(styles.successModal, className)}
      modalKey={modalKey}
    >
      <button
        type="button"
        className={clsx('no-button-styling', styles.modalClose)}
        onClick={onClose}
        aria-label="Close"
      >
        <MdOutlineCancel data-testid="closeIcon" />
      </button>
      <img
        src={Check}
        alt=""
      />
      {children}
    </GenericModal>
  )
}

export default SuccessModal
