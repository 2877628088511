type Props = {
  groupName?: string
  value?: any
  displayComponent?: React.ReactNode
  isChecked?: boolean
  isDisabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const StepContentSelection = ({
  groupName,
  value,
  isChecked,
  isDisabled,
  displayComponent,
  onChange,
}: Props) => {
  return (
    <li>
      <label data-testid={`option-${value}`}>
        <input
          className="with-gap"
          name={groupName}
          type="radio"
          value={value}
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled}
        />
        {displayComponent}
      </label>
    </li>
  )
}

export default StepContentSelection
