import { BASKET_FETCH_SUCCESS } from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { CANCELED_ERROR, loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:baskets:fetchBasket')

export default (basketId) => {
  return (dispatch) => {
    return new Promise<void>((resolve, reject) => {
      const failedFetch = (error) => {
        if (error.name === CANCELED_ERROR) {
          debug(`Cancelled duplicate request`)
          return
        }

        const reason = loggableError(error)
        debug(`Failed fetching basket ${basketId} articles`, reason)
        reject(reason)
      }

      return axios
        .get(`/api/v1/baskets/${basketId}`)
        .then((response) => {
          const { data } = response
          dispatch({
            type: BASKET_FETCH_SUCCESS,
            payload: {
              basketId,
              basketArticleIds: data.basket_article_ids,
              requesterId: data.requester_id,
            },
            meta: {
              analytics: {
                eventName: BASKET_FETCH_SUCCESS,
                eventPayload: {
                  basketId,
                  requesterId: data.requester_id,
                },
              },
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}
