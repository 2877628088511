import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import YouTube from 'react-youtube'
import { trackEvent } from 'services/Analytics'
import styles from './VolunteerThoughts.module.scss'

const youTubeStates = {
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
}

const VolunteerThoughts = () => {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)

  const trackFirstVideoPlay = (youTubeState) => {
    if (youTubeState === youTubeStates.PLAYING && isPlaying === false) {
      setIsPlaying(true)
    }
  }

  useEffect(() => {
    if (isPlaying === true) {
      trackEvent('FWH_LANDING_VIDEO_PLAY')
    }
  }, [isPlaying])
  return (
    <>
      <h3 className={styles.thoughtsTitle}>{t('landing-page.volunteer-thoughts.title')}</h3>
      <YouTube
        videoId="5bMEtL7BAz4"
        className={styles.youtube}
        onStateChange={(state) => {
          trackFirstVideoPlay(state.data)
        }}
      />
    </>
  )
}

export default VolunteerThoughts
