import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { modalKeys } from 'config/modals'
import { MY_COLLECTIONS_URL } from 'config/routes'
import { formatDateToDayAndMonth } from 'helpers/time'
import { useModal } from 'hooks/application'
import Alert from 'components/common/Alert'
import Checkbox from 'components/common/Checkbox'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { Stack } from 'components/layout/primitives'
import styles from './SameDayCollectionInfo.module.scss'

const SameDayCollectionInfo = ({
  confirmSpace,
  setConfirmSpace,
  sameDayCollectionCount,
  date,
  storeTimezone,
}) => {
  const { t } = useTranslation()
  const { closeModal: closeClaimCollectionModal } = useModal(modalKeys.CLAIM_COLLECTION)

  if (!sameDayCollectionCount || sameDayCollectionCount === 0) {
    return null
  }

  if (sameDayCollectionCount === 1) {
    return (
      <Stack
        gap="1.5rem"
        className={styles.root}
      >
        <Alert variant="info">
          <SetInnerHtmlSafe>
            {t('collection-slots.claim.confirmation-modal.same-day-collection-one', {
              oneCollectionBold: `<strong>${t('collection-slots.claim.confirmation-modal.one-collection')}</strong>`,
              date: formatDateToDayAndMonth(date, storeTimezone),
            })}
          </SetInnerHtmlSafe>
        </Alert>
        <Checkbox
          checked={confirmSpace}
          testId="confirm-space-checkbox"
          label={t('collection-slots.claim.confirmation-modal.same-day-collection-one-confirm')}
          onChange={() => setConfirmSpace((value) => !value)}
        />
      </Stack>
    )
  }

  if (sameDayCollectionCount === 2) {
    return (
      <Stack
        gap="2.5rem"
        className={styles.root}
      >
        <Alert variant="status">
          <SetInnerHtmlSafe>
            {t('collection-slots.claim.confirmation-modal.same-day-collection-two', {
              twoCollectionsBold: `<strong>${t('collection-slots.claim.confirmation-modal.two-collections')}</strong>`,
              date: formatDateToDayAndMonth(date, storeTimezone),
            })}
          </SetInnerHtmlSafe>
        </Alert>
        <Checkbox
          checked={confirmSpace}
          testId="confirm-space-checkbox"
          label={t('collection-slots.claim.confirmation-modal.same-day-collection-two-confirm')}
          onChange={() => setConfirmSpace((value) => !value)}
        />
      </Stack>
    )
  }

  return (
    <Stack
      gap="2rem"
      className={styles.root}
    >
      <Alert variant="error">
        <SetInnerHtmlSafe>
          {t('collection-slots.claim.confirmation-modal.cannot-claim-collection', {
            date: formatDateToDayAndMonth(date, storeTimezone),
          })}
        </SetInnerHtmlSafe>
      </Alert>
      <Link
        to={MY_COLLECTIONS_URL}
        className={styles.viewMyCollections}
        onClick={() => {
          closeClaimCollectionModal()
        }}
      >
        {t('collection-slots.claim.confirmation-modal.view-my-collections')}
      </Link>
    </Stack>
  )
}

export default SameDayCollectionInfo
