import { useTranslation } from 'react-i18next'
import { trackEvent } from 'services/Analytics'
import carrotWithStore from 'images/carrotWithStore.webp'
import Button from 'components/common/Button'
import Card from 'components/common/Cards/Card'
import styles from './NoCollectionsNearMe.module.scss'

type NoCollectionsNearMeType = {
  collectionCount: number
}

const NoCollectionsNearMe = ({ collectionCount }: NoCollectionsNearMeType) => {
  const { t } = useTranslation()

  return (
    <Card className={styles.noCollectionsNearMe}>
      <Card.Heading>{t('available-collections.no-collections-near-me.heading')}</Card.Heading>
      <Card.Image>{carrotWithStore}</Card.Image>
      <Card.TextSection className={styles.textSection}>
        <strong className={styles.subheading}>
          {collectionCount === 0
            ? t('available-collections.no-collections-near-me.subheading')
            : t('available-collections.no-collections-near-me.subheadingFewCollections')}
        </strong>

        <p>{t('available-collections.no-collections-near-me.description1')}</p>
        <p>{t('available-collections.no-collections-near-me.description2')}</p>
        <p>{t('available-collections.no-collections-near-me.description3')}</p>
      </Card.TextSection>
      <Button
        className={styles.becomeAnAmbassadorButton}
        href={t('external-urls.squad-starter-link')}
        onClick={() => trackEvent('FWH_BECOME_AMBASSADOR')}
        target="_blank"
      >
        {t('available-collections.no-collections-near-me.buttonLabel')}
      </Button>
    </Card>
  )
}

export default NoCollectionsNearMe
