import { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import styles from './RadioButtonGroup.module.scss'

type Props = {
  name: string
  selectedValue: string
  options: { value: string; label: string }[]
  alignLabelLeft?: boolean
}

const RadioButtonGroup = ({ name, selectedValue, options, alignLabelLeft = true }: Props) => {
  const { control } = useFormContext()

  return (
    <section>
      {options.map(({ label, value }) => {
        return (
          <div key={value}>
            <Controller
              control={control}
              defaultValue={selectedValue}
              name={name}
              render={({ field: { onChange, onBlur } }) => {
                return (
                  <label
                    className={clsx({
                      [styles.radioButtonLabelLeft]: alignLabelLeft === true,
                    })}
                  >
                    <input
                      type="radio"
                      className="with-gap"
                      name={name}
                      value={value}
                      defaultChecked={value === selectedValue}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                    <span>{label}</span>
                  </label>
                )
              }}
            />
          </div>
        )
      })}
    </section>
  )
}

export default memo(RadioButtonGroup)
