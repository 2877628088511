import { useTranslation } from 'react-i18next'
import { MdGroups, MdHandshake, MdRestaurant, MdVolunteerActivism } from 'react-icons/md'
import clsx from 'clsx'
import styles from './VolunteeringInfoSection.module.scss'

type Props = {
  className?: string
}

const VolunteeringInfoSection = ({ className }: Props) => {
  const { t } = useTranslation()
  return (
    <section className={clsx(styles.volunteeringInfoSection, className)}>
      <p className={styles.description}>{t('join_intro_page.volunteering_info.description')}</p>
      <div className={styles.pointsContainer}>
        <div className={styles.layout}>
          <div className={styles.pointWrapper}>
            <span className={styles.icon}>
              <MdRestaurant data-testid="restaurantIcon" />
            </span>
            <p>{t('join_intro_page.volunteering_info.point_1')}</p>
          </div>
          <div className={styles.pointWrapper}>
            <span className={styles.icon}>
              <MdHandshake data-testid="handshakeIcon" />
            </span>
            <p>{t('join_intro_page.volunteering_info.point_2')}</p>
          </div>
        </div>

        <div className={styles.layout}>
          <div className={styles.pointWrapper}>
            <span className={styles.icon}>
              <MdVolunteerActivism data-testid="volunteerActivismIcon" />
            </span>
            <p>{t('join_intro_page.volunteering_info.point_3')}</p>
          </div>
          <div className={styles.pointWrapper}>
            <span className={styles.icon}>
              <MdGroups data-testid="groupsIcon" />
            </span>
            <p>{t('join_intro_page.volunteering_info.point_4')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VolunteeringInfoSection
