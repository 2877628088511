import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchUser from '@redux/thunks/users/fetchUser'
import Debug from 'helpers/debug'
import UserAvatar from 'components/common/UserAvatar'

const debug = Debug('components:basket:requester:index')

type Props = {
  basketId: number
}

const Requester = ({ basketId }: Props) => {
  const userId = useAppSelector((state) => state?.baskets.items[basketId]?.requesterId)
  const user = useAppSelector((state) => state?.users.items[userId])
  const name = user?.name

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (undefined === name) {
      dispatch(fetchUser(userId)).catch(debug)
    }
  }, [])

  return (
    <div className="icon-wrapper">
      <UserAvatar userId={userId} />
      <h3>{name}</h3>
    </div>
  )
}

export default Requester
