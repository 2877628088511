import { memo } from 'react'
import { motion } from 'framer-motion'
import { BASKET_CLOSE as ANIMATION_OPTIONS } from 'helpers/animations'

type Props = {
  children: React.ReactNode
}

const AnimationWrapper = ({ children }: Props) => {
  return (
    <motion.div
      className="basket-container"
      transition={{ duration: ANIMATION_OPTIONS.duration }}
      animate="visible"
      exit="exit"
      variants={ANIMATION_OPTIONS.variants}
    >
      <div className="card">{children}</div>
    </motion.div>
  )
}

export default memo(AnimationWrapper)
