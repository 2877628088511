import {
  COLLECTION_SWAP_ERROR,
  COLLECTION_SWAP_START,
  COLLECTION_SWAP_SUCCESS,
} from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:swap')

const swap = ({
  selectedCollectionId: collectionId,
  swapForCollectionId,
  selectedStoreId: storeId,
}) => {
  return (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: COLLECTION_SWAP_START,
      })

      const failedFetch = (error) => {
        const reason = loggableError(error)
        debug(`Failed to swap collection ${collectionId}`, reason)
        dispatch({
          type: COLLECTION_SWAP_ERROR,
          payload: {
            errors: error?.response?.data?.errors?.base || [],
          },
        })
        reject(reason)
      }

      return axios
        .patch(`/api/v1/collections/${collectionId}/swap`, {
          with_collection_id: swapForCollectionId,
        })
        .then((response) => {
          const { id: newCollectionId } = response.data
          const state = getState()
          const collection = state.collections?.items[collectionId]
          const store = state.stores?.items[collection.storeId]
          const business = state.stores?.businesses[store.businessId]
          dispatch({
            type: COLLECTION_SWAP_SUCCESS,
            payload: {
              collectionId,
              swapForCollectionId,
              newCollectionId,
              storeId,
            },
            meta: {
              analytics: {
                eventName: COLLECTION_SWAP_SUCCESS,
                eventPayload: {
                  id: collectionId,
                  type: collection?.type,
                  businessId: store.businessId,
                  business: business?.name,
                  store: store?.name,
                  storeId,
                  nextCollection: collection?.nextCollection,
                  newCollectionId,
                },
              },
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}

export default swap
