import { COLLECTION_UNASSIGN_MODAL_CLOSE } from '@redux/actionTypes'

const closeUnassignModal = () => {
  return (dispatch) => {
    dispatch({
      type: COLLECTION_UNASSIGN_MODAL_CLOSE,
    })
  }
}
export default closeUnassignModal
