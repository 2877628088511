import clsx from 'clsx'
import styles from './Note.module.scss'

type NoteProps = {
  title: string
  note: React.ReactNode
  className?: string
}

const Note: React.FC<NoteProps> = ({ title, note, className }) => {
  return (
    <div className={clsx(className, styles.wrapper)}>
      <h3 className={styles.title}>{title}</h3>
      <span className={styles.text}>{note}</span>
    </div>
  )
}

export default Note
