import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineLock } from 'react-icons/md'
import clsx from 'clsx'
import { trackEvent } from 'services/Analytics'
import { INFO } from 'config/alert'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import styles from './QuizButtons.module.scss'

type Props = {
  isResultsStep: boolean
  paginate: (step: number) => void
  onSubmit: (event?: any) => void
  step: number
  questions: any[]
  retryQuiz: () => void
  isRetryLocked: boolean
  inductionId: number
  inductionTitle: string
}

const InductionQuizButtons = ({
  isResultsStep,
  paginate,
  onSubmit,
  step,
  questions,
  retryQuiz,
  isRetryLocked,
  inductionId,
  inductionTitle,
}: Props) => {
  const { t } = useTranslation()
  const [quizRetryAttempted, setQuizRetryAttempted] = useState(false)

  const handleRetry = () => {
    setQuizRetryAttempted(false)
    retryQuiz()
  }

  const handleRetryAttempt = () => {
    setQuizRetryAttempted(true)
    trackEvent('INDUCTION_QUIZ_RETRY', {
      induction_title: inductionTitle,
      induction_id: inductionId,
      button_state: 'inactive',
      video: true,
    })
  }

  if (isResultsStep === true) {
    return (
      <div className={styles.inductionRetryQuiz}>
        {isRetryLocked === true ? (
          <>
            {quizRetryAttempted === true && (
              <div className={styles.alertInfoMessage}>
                <Alert variant={INFO}>
                  {t('inductions.page.show.quiz.play_video_instruction')}
                </Alert>
              </div>
            )}
            <Button
              className={styles.tryQuizAgain}
              secondary
              onClick={handleRetryAttempt}
              leftContent={<MdOutlineLock />}
            >
              {t('inductions.page.show.quiz.try_quiz_again')}
            </Button>
          </>
        ) : (
          <Button
            className="try-quiz-again"
            secondary
            onClick={handleRetry}
          >
            {t('inductions.page.show.quiz.try_quiz_again')}
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className={styles.inductionQuizButtons}>
      <Button
        className={clsx({ [styles.hideButton]: step <= 0 })}
        secondary
        onClick={() => paginate(-1)}
      >
        {t('inductions.page.show.quiz.previous')}
      </Button>
      {step + 1 === questions.length ? (
        <Button
          className={styles.checkAnswersButton}
          onClick={onSubmit}
        >
          {t('inductions.page.show.quiz.button-submit')}
        </Button>
      ) : (
        <Button onClick={() => paginate(1)}>{t('inductions.page.show.quiz.next')}</Button>
      )}
    </div>
  )
}

export default InductionQuizButtons
