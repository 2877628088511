import clsx from 'clsx'
import Button from 'components/common/Button'
import styles from './InfoBannerWithButton.module.scss'

type Props = {
  title: string
  buttonText: string
  onButtonClick: () => void
}

const InfoBannerWithButton = ({ title, buttonText, onButtonClick }: Props) => {
  return (
    <div className={clsx(styles.infoBannerWithButton, 'card')}>
      <div className={clsx(styles.cardContent)}>
        <p>{title}</p>
        <Button
          secondary
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default InfoBannerWithButton
