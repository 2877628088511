import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@redux/hooks'
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences'
import updateUserPreferences from '@redux/thunks/preferences/updateUserPreferences'
import Debug from 'helpers/debug'
import Button from 'components/common/Button'
import Card from 'components/common/Cards/Card'
import styles from './DismissableCard.module.scss'

const debug = Debug('components:common:cards:dismissable-card')

type Props = {
  heading: string
  image: string
  children: React.ReactNode
  buttonText: string
  buttonLink: string
  userPreferenceToUpdate: string
  handleDismissClick?: () => void
  handleActionClick?: () => void
}

const DismissableCard = ({
  heading,
  image,
  children,
  buttonText,
  buttonLink,
  userPreferenceToUpdate,
  handleActionClick,
  handleDismissClick,
}: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleDismissButtonClick = async () => {
    try {
      await dispatch(updateUserPreferences({ [userPreferenceToUpdate]: true }))
      await dispatch(fetchUserPreferences())
      if (handleDismissClick !== undefined) {
        handleDismissClick()
      }
    } catch {
      return debug
    }
  }

  const handleActionButtonClick = () => {
    if (handleActionClick !== undefined) {
      handleActionClick()
    }
  }

  return (
    <Card>
      <Card.Heading>{heading}</Card.Heading>
      <Card.Image>{image}</Card.Image>
      <Card.TextSection>{children}</Card.TextSection>
      <div className={styles.buttonContainer}>
        <Button
          target="_blank"
          className={styles.linkButton}
          href={buttonLink}
          onClick={handleActionButtonClick}
        >
          {buttonText}
        </Button>
        <Button
          className={styles.dismissButton}
          onClick={handleDismissButtonClick}
        >
          {t('common.cards.dismissable-card.dismiss-button')}
        </Button>
      </div>
    </Card>
  )
}

export default DismissableCard
