import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchUser from '@redux/thunks/users/fetchUser'
import { MINIMUM_FOOD_HERO_RATING } from 'config/user'
import Debug from 'helpers/debug'
import { isUserFoodHero } from 'helpers/user'
import { useCurrentUserId } from 'hooks/users'

const debug = Debug('hooks:use-authenticated-user')

const useAuthenticatedUser = ({ shouldFetchUser = false } = {}) => {
  const dispatch = useAppDispatch()
  const currentUserId = useCurrentUserId()
  const user = useAppSelector((state) => state.users.currentUser)
  const userRoles = user?.roles
  const isVerified = user?.verified
  const rating = user?.rating

  useEffect(() => {
    if (
      shouldFetchUser === true &&
      currentUserId !== undefined &&
      (rating === undefined || (userRoles || []).length === 0)
    ) {
      dispatch(fetchUser(currentUserId)).catch(debug)
    }
  }, [userRoles, rating, currentUserId])

  return {
    isUserLoaded: Boolean(user),
    isFoodHero: userRoles && isUserFoodHero(userRoles),
    isUserVerified: isVerified,
    hasLowRating: rating !== null && rating < MINIMUM_FOOD_HERO_RATING,
    user,
  }
}

export default useAuthenticatedUser
