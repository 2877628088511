import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchArticle from '@redux/thunks/articles/fetchArticle'
import fetchBasketArticle from '@redux/thunks/baskets/fetchBasketArticle'
import Debug from 'helpers/debug'
import ArticleQuantity from './ArticleQuantity'

const debug = Debug('components:basket:article')

type Props = {
  basketArticleId: number
}

const Article = ({ basketArticleId }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const basketArticle = useAppSelector((state) => state?.baskets.articles?.[basketArticleId])
  const articleId = basketArticle?.articleId
  const article = useAppSelector((state) => state?.articles?.items[articleId])
  const available = article?.available
  const total = article?.total
  const title = article?.title

  useEffect(() => {
    if (articleId === undefined) {
      dispatch(fetchBasketArticle(basketArticleId)).catch(debug)
    }
  }, [])

  useEffect(() => {
    if (articleId !== undefined && title === undefined) {
      dispatch(fetchArticle(articleId)).catch(debug)
    }
  }, [articleId, title])

  return (
    <div className="article-container">
      <label>
        <strong>{title}</strong>
        {total !== undefined &&
          available !== undefined &&
          t('baskets.available_quantity', { available, total })}
      </label>
      <ArticleQuantity basketArticleId={basketArticleId} />
    </div>
  )
}

export default Article
