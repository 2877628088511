import clsx from 'clsx'
import { useAppSelector } from '@redux/hooks'
import { getMapUrl } from 'helpers/map'
import Address from '../Address'

type Props = {
  storeId: number
  logoUrl: string
  location: {
    latitude: number
    longitude: number
  }
  small?: boolean
  addressLink?: boolean
}

const StoreInfo = ({ storeId, logoUrl, location, small = false, addressLink = true }: Props) => {
  const mapUrl = getMapUrl(location)
  const {
    name: storeName,
    address,
    businessId,
  } = useAppSelector((state) => state.stores.items[storeId] ?? {})
  const { name: businessName } = useAppSelector(
    (state) => state.stores.businesses[businessId] ?? {},
  )

  return (
    <div className={clsx('store-info-container', small === true && 'small')}>
      <div className="store-logo">
        <img
          src={logoUrl}
          alt={`${businessName} logo`}
        />
      </div>

      <div className="store-info">
        <h3 className="store-name">
          <span>{businessName}</span> | <span>{storeName}</span>
        </h3>

        {addressLink === true ? (
          <a
            href={mapUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Address address={address} />
          </a>
        ) : (
          <Address address={address} />
        )}
      </div>
    </div>
  )
}

export default StoreInfo
