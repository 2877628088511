import { COLLECTIONS_FETCH_START, COLLECTIONS_FETCH_SUCCESS } from '@redux/actionTypes'
import { cleanCollections } from '@redux/utilities/handleCollectionData'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { CANCELED_ERROR, loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:fetchMyCollections')

export default ({ storeId = undefined } = {}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const failedFetch = (error) => {
        if (error.name === CANCELED_ERROR) {
          debug(`Cancelled duplicate request`)
          return
        }

        const reason = loggableError(error)
        debug(`Failed fetching user collections`, reason)
        reject(reason)
      }

      const baseUrl = window.location.origin
      const url = new URL(`${baseUrl}/api/v1/collections`)

      if (storeId !== undefined) {
        url.searchParams.append('store_id', storeId)
      }

      dispatch({
        type: COLLECTIONS_FETCH_START,
      })
      return axios
        .get(url.toString())
        .then((response) => {
          const { data } = response
          const collections = cleanCollections(data)
          dispatch({
            type: COLLECTIONS_FETCH_SUCCESS,
            payload: {
              collections,
            },
          })
          resolve(collections)
        })
        .catch(failedFetch)
    })
  }
}
