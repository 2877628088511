import clsx from 'clsx'
import { useAppSelector } from '@redux/hooks'

const MyAvatar = ({ className }) => {
  const avatarUrl = useAppSelector((state) => state.users.currentUser?.avatar?.small)

  return (
    <div className={clsx('icon', className)}>
      <img
        alt=""
        data-testid="userAvatar"
        src={avatarUrl}
      />
    </div>
  )
}

export default MyAvatar
