import { useTranslation } from 'react-i18next'
import { MdOutlineSchool } from 'react-icons/md'
import styles from './InductionProgress.module.scss'
import InformationIcon from './InformationIcon'

type InductionProgressType = {
  completedInductionsCount: number
  inductionsCount: number
  collectionType: string
}

const InductionProgress = ({
  completedInductionsCount,
  inductionsCount,
  collectionType,
}: InductionProgressType) => {
  const { t } = useTranslation()

  if (inductionsCount === undefined || completedInductionsCount === undefined) {
    return null
  }

  return (
    <div className={styles.inductionProgress}>
      <span>
        <InformationIcon
          Icon={MdOutlineSchool}
          collectionType={collectionType}
        />
      </span>
      <span className={styles.inductionProgressValues}>
        {`${completedInductionsCount}/${inductionsCount} `}
        {t('collections.available.inductions')}
      </span>
    </div>
  )
}

export default InductionProgress
