import { MODAL_CLOSE } from '@redux/actionTypes'

export default ({ key }) => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CLOSE,
      payload: { key },
    })
  }
}
