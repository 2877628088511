import { Outlet } from 'react-router-dom'
import Navigation from 'components/layout/Navigation'

const Layout = () => {
  return (
    <>
      <Navigation />
      <Outlet />
    </>
  )
}

export default Layout
