import clsx from 'clsx'
import styles from './ProgressBullets.module.scss'

type Props = {
  readonly current: number
  readonly total: number
}

const ProgressBullets = ({ current, total }: Props) => {
  return (
    <div
      className={styles.progressBullets}
      data-testid="progress-bullets"
    >
      {[...Array(total)].map((_, index) => {
        return (
          <div
            key={index}
            className={clsx(styles.bullet, { [styles.current]: current === index })}
          />
        )
      })}
    </div>
  )
}

export default ProgressBullets
