import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { RECOMMENDATION } from 'config/alert'
import DisplayedCollection from 'components/JoinIntro/DisplayedCollection'
import { CollectionPropType } from 'components/JoinIntro/DisplayedCollection/DisplayedCollection'
import Alert from 'components/common/Alert'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import styles from './CollectionsOverview.module.scss'

type Props = {
  adhocCollection: CollectionPropType
  ongoingCollection: CollectionPropType
}

const CollectionsOverview = ({ adhocCollection, ongoingCollection }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={styles.collectionOverview}>
      <h3 className={styles.header}>{t('join-intro-page.collections-overview.header')}</h3>
      <p className={styles.description}>{t('join-intro-page.collections-overview.description')}</p>
      <div className={styles.collections}>
        <div className={clsx(styles.collection, styles.adhocCollection)}>
          <p className={clsx(styles.adhocCollectionDescription, styles.collectionDescription)}>
            <SetInnerHtmlSafe>
              {t('join-intro-page.collections-overview.adhoc-collection.description.text', {
                'bold-text': `<b>${t(
                  'join-intro-page.collections-overview.adhoc-collection.description.bold-text',
                )}</b>`,
              })}
            </SetInnerHtmlSafe>
          </p>
          <DisplayedCollection collection={adhocCollection} />
        </div>
        <div className={styles.collection}>
          <p className={clsx(styles.ongoingCollectionDescription, styles.collectionDescription)}>
            <SetInnerHtmlSafe>
              {t('join-intro-page.collections-overview.ongoing-collection.description.text', {
                'bold-text': `<b>${t(
                  'join-intro-page.collections-overview.ongoing-collection.description.bold-text',
                )}</b>`,
              })}
            </SetInnerHtmlSafe>
          </p>
          <DisplayedCollection collection={ongoingCollection} />
        </div>
      </div>
      <Alert variant={RECOMMENDATION}>
        {t('join-intro-page.collections-overview.alert-message')}
      </Alert>
    </div>
  )
}

export default CollectionsOverview
