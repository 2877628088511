import {
  CURRENT_USER_FETCH_ERROR,
  USER_FETCH_FAIL,
  CURRENT_USER_FETCH_SUCCESS,
} from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:users:fetch-current-user-data')

const fetchCurrentUser = () => {
  return async (dispatch) => {
    const onFail = (error) => {
      const reason = loggableError(error)
      debug(`Failed to fetch current user data`, reason)
      if (error.response?.status === 401) {
        window.userId = undefined
        dispatch({
          type: USER_FETCH_FAIL,
        })
        dispatch({ type: CURRENT_USER_FETCH_ERROR })
      }
    }

    return axios
      .get(`/api/v1/users/me`)
      .then((response) => {
        const nearbyUserCountIncludingMe = response.data.location.nearby_closer_count + 1

        const currentUser = {
          id: response.data.id,
          email: response.data.email,
          roles: response.data.roles,
          nearbyUserCount: nearbyUserCountIncludingMe,
          verified: response.data.verified,
          rating: response.data.rating.rating,
          address: {
            addressLine1: response.data.address.address_line_1,
            addressLine2: response.data.address.address_line_2,
            addressLine3: response.data.address.address_line_3,
            addressLine4: response.data.address.address_line_4,
            county: response.data.address.region,
            city: response.data.address.locality,
            country: response.data.address.country,
            postcode: response.data.address.postcode,
            telephoneNumber: response.data.address.telephone_number,
          },
          avatar: response.data.current_avatar,
          name: {
            firstName: response.data.name.first_name,
          },
          location: {
            latitude: response.data.notifications.home.latitude,
            longitude: response.data.notifications.home.longitude,
          },
        }

        dispatch({
          type: CURRENT_USER_FETCH_SUCCESS,
          payload: {
            currentUser,
          },
        })

        window.userId = Number(currentUser.id)
      })
      .catch(onFail)
  }
}

export default fetchCurrentUser
