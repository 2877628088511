import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { useAppSelector, useAppDispatch } from '@redux/hooks'
import fetchFeatureFlags from '@redux/thunks/application/fetchFeatureFlags'
import { INFO } from 'config/alert'
import Debug from 'helpers/debug'
import { getMapUrl, getSignedStaticMapUrl } from 'helpers/map'
import { useIsMobile } from 'hooks/application'
import { useIsFeatureFlagEnabled } from 'hooks/application'
import { useCollection } from 'hooks/collections'
import AvailableCollectionInformation from 'components/AvailableCollections/AvailableCollectionInformation'
import ClaimCollection from 'components/AvailableCollections/ClaimCollection'
import UnlimitedSlotLabel from 'components/Collections/UnlimitedSlotLabel'
import Alert from 'components/common/Alert'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { Spacer } from 'components/layout/primitives'

const debug = Debug('components:collections:available-collection-details-card')

type AvailableCollectionDetailsCardType = {
  collectionId: number
}

const AvailableCollectionDetailsCard = ({ collectionId }: AvailableCollectionDetailsCardType) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch()
  const [mapUrl, setMapUrl] = useState()

  const homeLocation = useAppSelector((state) => state.users.currentUser?.location, shallowEqual)
  const isSimpleSlotLimitsFeatureEnabled = useIsFeatureFlagEnabled('simpleSlotLimits')

  const { data: collection } = useCollection({
    collectionId,
    dataToInclude: { inductions: true },
  })
  const requiredInductions = collection?.requiredInductions
  const completedInductions = collection?.completedInductions
  const store = collection?.store
  const business = collection?.business
  const storeName = store?.name
  const businessName = business?.name
  const storeLocation = store?.location
  const latitude = store?.location.latitude
  const longitude = store?.location.longitude
  const collectionNotes = collection?.notes
  const donationConfirmedAt = collection?.donationConfirmedAt
  const mapLinkUrl = getMapUrl(storeLocation)

  useEffect(() => {
    const getMapUrl = async () => {
      let mapSize = { width: 250, height: 250 }
      if (isMobile) {
        mapSize = { width: 334, height: 156 }
      }
      const mapUrl = await getSignedStaticMapUrl({ latitude, longitude, ...mapSize })
      setMapUrl(mapUrl)
    }
    getMapUrl()
    dispatch(fetchFeatureFlags()).catch(debug)
  }, [])

  const displayUnlimitedSlotLabel =
    !isSimpleSlotLimitsFeatureEnabled && collection?.derestricted === true

  return (
    <div className="card available-card-wrapper">
      <div className="available-collections-details-card card">
        <div className="card-content">
          <div className="collection-information">
            <div>
              {displayUnlimitedSlotLabel && (
                <>
                  <UnlimitedSlotLabel />
                  <Spacer x="1.25rem" />
                </>
              )}
              <h3 className="business-name">{`${businessName} |`}</h3>
              <h3 className="store-name">{storeName}</h3>
              <AvailableCollectionInformation
                type={collection?.type}
                frequency={collection?.frequency}
                nextCollection={collection?.nextCollection}
                homeLocation={homeLocation}
                storeLocation={storeLocation}
                storeAddress={store?.address}
                storeTimezone={store?.timezone}
                completedInductionsCount={completedInductions?.length}
                inductionsCount={requiredInductions?.length}
                businessName={businessName}
                donationConfirmedAt={donationConfirmedAt}
              />
              {isMobile === false && collectionNotes !== undefined && (
                <div className="alert-info-message">
                  <Alert variant={INFO}>{collectionNotes}</Alert>
                </div>
              )}
            </div>
            <div>
              {isMobile === true && (
                <div className="mobile-map-info">
                  <a
                    className="directions-link"
                    href={mapLinkUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('collection_slots.partials.slot.directions')}
                  </a>
                  <div className="mobile-map">
                    <a
                      href={mapLinkUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="small map of store location"
                        src={mapUrl}
                        data-testid="map-image"
                      />
                    </a>
                  </div>
                  <div>
                    {collectionNotes !== undefined && (
                      <div className="alert-info-message">
                        <Alert variant={INFO}>
                          <SetInnerHtmlSafe>{collectionNotes}</SetInnerHtmlSafe>
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <ClaimCollection
                collectionId={collectionId}
                requiredInductions={requiredInductions}
              />
            </div>
          </div>
          {isMobile === false && (
            <div className="right-side-map">
              <a
                href={mapLinkUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="larger map of store location"
                  src={mapUrl}
                  data-testid="map-image"
                />
              </a>
              <a
                className="directions-link"
                href={mapLinkUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t('collection_slots.partials.slot.directions')}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AvailableCollectionDetailsCard
