import { useTranslation } from 'react-i18next'
import styles from './CollectionConfirmationInfo.module.scss'

const CollectionConfirmationInfo = ({
  businessName,
  storeName,
  storeAddress,
  collectionTime,
  collectionType,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <h3 className={styles.businessAndStoreName}>
        {t('collection-slots.collection-confirmation-info.business-and-store', {
          businessName,
          storeName,
        })}
      </h3>
      <p className={styles.address}>
        {storeAddress &&
          Object.values(storeAddress).map((addressLine: string, index) => {
            return <span key={addressLine + index}>{addressLine}</span>
          })}
      </p>
      <p className={styles.on}>{t('collection-slots.collection-confirmation-info.on')}</p>
      <p className={styles.collectionTime}>{collectionTime}</p>
      <p className={styles.collectionType}>
        {t('collection-slots.collection-confirmation-info.collection-type', {
          collectionType: t(`collection-slots.claim.types.${collectionType}`),
        })}
      </p>
    </div>
  )
}

export default CollectionConfirmationInfo
