import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'
import {
  NOTIFICATION_PREFERENCE_ADHOC,
  NOTIFICATION_PREFERENCE_ONGOING,
} from 'helpers/notificationPreferences'
import { getCurrentUserId } from 'helpers/user'

const debug = Debug('redux:thunks:preferences:updateUserPreferences')

const updateUserPreferences = createAsyncThunk(
  'preferences/updateUserPreferences',
  async (preferences: Record<number, boolean>, { rejectWithValue }) => {
    return axios
      .put(`/api/v1/users/${getCurrentUserId()}/preferences`, {
        user_preferences: preferences,
      })
      .then(() => {
        return {
          meta: {
            analytics: {
              eventName: 'NOTIFICATIONS_UPDATE',
              eventPayload: {
                ongoingType: preferences[NOTIFICATION_PREFERENCE_ONGOING],
                oneOffType: preferences[NOTIFICATION_PREFERENCE_ADHOC],
              },
            },
          },
        }
      })
      .catch((error) => {
        debug(`Failed updating user preferences`, loggableError(error))
        return rejectWithValue(error.response.status)
      })
  },
)

export default updateUserPreferences
