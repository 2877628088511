import { useTranslation } from 'react-i18next'
import DocumentsList from 'components/Documents/DocumentsList'
import SceneHeader from 'components/common/SceneHeader'

const DocumentsScene = () => {
  const { t } = useTranslation()
  return (
    <div id="documents-scene">
      <SceneHeader
        title={t('documents.title')}
        description={t('documents.description')}
      />
      <DocumentsList />
    </div>
  )
}

export default DocumentsScene
