/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useAppDispatch } from '@redux/hooks'
import toggleSidebar from '@redux/thunks/application/toggleSidebar'
import { trackEvent } from 'services/Analytics'
import { getSidebarLinks } from 'config/routes'
import { useIsMobile } from 'hooks/application'
import styles from './Link.module.scss'

type Props = {
  icon?: React.ReactNode
  type: string
  url?: string
  target?: string
  trackingEvent?: string
}

const SidebarLink = ({ icon, type, url, target = '_self', trackingEvent }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentPath = location.pathname

  const isMobile = useIsMobile()

  const sidebarLinks = useMemo(() => {
    return getSidebarLinks(t)
  }, [])
  const configUrl = sidebarLinks[type].url
  const linkUrl = url ?? configUrl

  const handleClick = () => {
    const eventName = trackingEvent || `NAVIGATE_${type.toUpperCase()}`
    trackEvent(eventName, {
      origin: 'menu',
    })
    if (isMobile) {
      dispatch(toggleSidebar())
    }
  }

  return (
    <Link
      to={linkUrl}
      target={target}
      onClick={handleClick}
      role="link"
    >
      <li className={clsx({ [styles.active]: currentPath?.includes(configUrl) === true })}>
        <span className={styles.iconAndTitle}>
          <span
            className={styles.icon}
            data-testid="sidebarIcon"
          >
            {icon}
          </span>
          <span
            className={styles.link}
            data-testid="sidenav-link"
          >
            {sidebarLinks[type].text}
          </span>
        </span>
      </li>
    </Link>
  )
}

export default SidebarLink
