import styles from './LoadingSpinner.module.scss'

type Props = {
  containerHeight?: string
}

const LoadingSpinner = ({ containerHeight = '100%' }: Props) => (
  <div
    className={styles.spinnerContainer}
    style={{ height: containerHeight }}
  >
    <span
      className={styles.spinner}
      data-testid="spinner"
    />
  </div>
)

export default LoadingSpinner
