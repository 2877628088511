import { COLLECTIONS_FETCH_SUCCESS } from '@redux/actionTypes'
import { cleanCollections } from '@redux/utilities/handleCollectionData'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { CANCELED_ERROR, loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:fetchStoreCollections')

const fetchStoreCollections = ({ storeId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const failedFetch = (error) => {
        if (error.name === CANCELED_ERROR) {
          debug(`Cancelled duplicate request`)
          return
        }

        const reason = loggableError(error)
        debug(`Failed fetching store ${storeId} collections`, reason)
        reject(reason)
      }

      return axios
        .get(`/api/v1/stores/${storeId}/collections`)
        .then((response) => {
          const { data } = response
          const collections = cleanCollections(data)
          dispatch({
            type: COLLECTIONS_FETCH_SUCCESS,
            payload: {
              collections,
            },
          })
          resolve(collections)
        })
        .catch(failedFetch)
    })
  }
}

export default fetchStoreCollections
