import clsx from 'clsx'
import { alertIcons } from 'config/alert'
import styles from './Alert.module.scss'

type Props = {
  className?: string
  variant: 'error' | 'info' | 'status' | 'message' | 'recommendation'
  children?: React.ReactNode
}

const Alert = ({ className, variant, children }: Props) => {
  const Icon = alertIcons[variant]

  return (
    <div className={clsx(styles.alert, styles[variant], className, 'alert')}>
      {Icon && (
        <span className={clsx(styles.alertIcon)}>
          <Icon data-testid={`${variant}Icon`} />
        </span>
      )}
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default Alert
