import React, { MutableRefObject, forwardRef, useEffect, useRef, useState } from 'react'
import { findDOMNode } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { useTranslation } from 'react-i18next'
import YouTube from 'react-youtube'
import type { YouTubePlayer } from 'react-youtube'
import { trackEvent } from 'services/Analytics'
import Debug from 'helpers/debug'
import { extractYoutubeVideoIdFromUrl, hasYoutubeVideo } from 'helpers/inductions'
import VideoDuration from 'components/Inductions/AdminContent/VideoDuration'
import styles from './AdminContent.module.scss'

const debug = Debug('components:inductions:admin-content')

const INJECTED_ID_PREFIX = 'youtube-video-'
const videoOptions = {
  height: '315',
  width: '560',
}

const youTubeStates = {
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
}

const injectCalendlyScript = () => {
  const head = document.querySelector('head')
  const script = document.createElement('script')
  script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
  head.appendChild(script)
}

type Props = {
  content: React.ReactNode
  inductionTitle?: string
  inductionId?: number
  setIsRetryLocked: (isLocked: boolean) => void
  isRewatch: boolean
}

const AdminContent = forwardRef(
  (
    { content, inductionId, inductionTitle, setIsRetryLocked, isRewatch }: Props,
    ref: MutableRefObject<YouTubePlayer | null>,
  ) => {
    const { t } = useTranslation()
    const contentRef = useRef<YouTubePlayer | null>(null)
    const [videoDurationInSeconds, setVideoDurationInSeconds] = useState()
    const [isPlaying, setIsPlaying] = useState(false)

    const handleYouTubeStateChange = (youTubeState) => {
      switch (youTubeState) {
        case youTubeStates.PLAYING:
          if (isPlaying === false) {
            setIsPlaying(true)
          }
          break

        case youTubeStates.ENDED:
        case youTubeStates.PAUSED:
          setIsPlaying(false)
          break

        default:
          break
      }
    }

    const replaceYoutubeVideo = (iframe) => {
      if (hasYoutubeVideo(content) === true) {
        const videoId = extractYoutubeVideoIdFromUrl(iframe.src)
        const injectedElementId = `${INJECTED_ID_PREFIX}${videoId}`

        iframe.insertAdjacentHTML('afterend', `<div id='${injectedElementId}'/>`)
        debug(`Replacing player for ${iframe.src}`)
        const root = createRoot(document.getElementById(injectedElementId))
        root.render(
          <>
            <p className={styles.videoInstruction}>{t('inductions.page.show.video_instruction')}</p>
            <YouTube
              videoId={videoId}
              opts={videoOptions}
              onReady={(event) => {
                setVideoDurationInSeconds(event.target.getDuration())
                ref.current = event.target
              }}
              onStateChange={(state) => {
                handleYouTubeStateChange(state.data)
              }}
            />
          </>,
        )
        iframe.parentNode.removeChild(iframe)
      }
    }

    useEffect(() => {
      // eslint-disable-next-line react/no-find-dom-node
      const element = findDOMNode(contentRef.current)
      if (element instanceof Element) {
        const iframes = element.querySelectorAll('iframe')
        if (iframes) {
          iframes.forEach((iframe) => {
            replaceYoutubeVideo(iframe)
          })
        }
      }
    }, [])

    useEffect(() => {
      if (isPlaying === true) {
        trackEvent('INDUCTION_VIDEO_PLAY', {
          induction_id: inductionId,
          induction_title: inductionTitle,
          rewatch: isRewatch,
        })

        if (isRewatch === true) {
          setIsRetryLocked(false)
        }
      }
    }, [isPlaying])

    useEffect(() => {
      // eslint-disable-next-line react/no-find-dom-node
      const calendlyNode = (findDOMNode(contentRef.current) as Element)?.querySelector(
        '.calendly-inline-widget',
      )
      if (calendlyNode) {
        debug('Setting up Calendly')
        injectCalendlyScript()
      }
    })

    return (
      <div className={styles.inductionContentWrapper}>
        {videoDurationInSeconds !== undefined && (
          <VideoDuration durationInSeconds={videoDurationInSeconds} />
        )}
        {content !== undefined && (
          <div
            className={styles.inductionContent}
            ref={contentRef}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    )
  },
)

export { INJECTED_ID_PREFIX, youTubeStates }

AdminContent.displayName = 'AdminContent'
export default AdminContent
