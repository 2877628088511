import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { INDUCTIONS_URL } from 'config/routes'
import Button from 'components/common/Button'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { Stack } from 'components/layout/primitives'
import styles from './QuizSuccess.module.scss'

type Props = {
  questionCount: number
  inductionTitle: string
}

const QuizSuccess = ({ questionCount, inductionTitle }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const isOriginDefined = location.state?.origin !== undefined

  const navigateToInductionsList = () => {
    if (isOriginDefined === true) {
      return navigate(location.state.origin)
    }

    return navigate(INDUCTIONS_URL)
  }

  const questionCountStyled = `<b>${questionCount}</b>`
  const questionCountText = t('inductions.page.show.quiz.results_description_2', {
    correct: questionCountStyled,
    total: questionCountStyled,
  })

  const inductionTitleText = t('inductions.page.show.quiz.well_done', {
    inductionTitle: `<b>${inductionTitle}</b>`,
  })

  const greatJobText = t('inductions.page.show.quiz.visit_documents.text', {
    highlightedText: `<b>${t('inductions.page.show.quiz.visit_documents.highlighted_text')}</b>`,
  })
  return (
    <div className={clsx('card', styles.inductionQuizResults)}>
      <div className="card-content">
        <Stack gap="1rem">
          <h2>{t('inductions.page.show.quiz.your_results')}</h2>
          <p>{t('inductions.page.show.quiz.results_description_1')}</p>
          <p>
            <SetInnerHtmlSafe>{questionCountText}</SetInnerHtmlSafe>
          </p>
          <p>
            <SetInnerHtmlSafe>{inductionTitleText}</SetInnerHtmlSafe>
          </p>
          <p>
            <SetInnerHtmlSafe>{greatJobText}</SetInnerHtmlSafe>
          </p>
          <Button onClick={navigateToInductionsList}>
            {t('inductions.page.show.quiz.view_remaining_inductions')}
          </Button>
        </Stack>
      </div>
    </div>
  )
}

export default QuizSuccess
