import { memo } from 'react'
import { modalKeys } from 'config/modals'
import { COLLECTION_ACTIONS } from 'helpers/collections'
import { useModal } from 'hooks/application'
import ActionsModal from './ActionsModal'

type Props = {
  collectionId: number
}

const SkipModal = ({ collectionId }: Props) => {
  const { isOpen } = useModal(modalKeys.SKIP_COLLECTION)

  if (isOpen === false) {
    return null
  }

  return (
    <ActionsModal
      collectionId={collectionId}
      mode={COLLECTION_ACTIONS.SKIP}
    />
  )
}

export default memo(SkipModal)
