import { useMemo } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { MdOutlineChecklist } from 'react-icons/md'
import { trackEvent } from 'services/Analytics'
import { useIsMobile } from 'hooks/application'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import styles from './WhatYouNeed.module.scss'

const WhatYouNeed = () => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const olioWebSite = t('common.links.olio-website')
  const appStoreLink = t('common.links.app-store')
  const playStoreLink = t('common.links.play-store')

  const whatYouNeedLink = useMemo(() => {
    if (isIOS === true) {
      return appStoreLink
    } else if (isAndroid === true) {
      return playStoreLink
    } else {
      return olioWebSite
    }
  }, [isIOS, isAndroid])

  const handleClick = () => {
    const eventName = 'FWH_LANDING_VERIFY'
    trackEvent(eventName)
  }

  const link = `<a href="${whatYouNeedLink}" class="no-button-styling ${styles.button}">
      ${t('landing-page.what-you-need.description1.bold-text')}</a>`

  const whatYouNeedText = t('landing-page.what-you-need.description1.text', {
    bold: link,
  })

  return (
    <>
      <div className={styles.heading}>
        <span className={styles.icon}>
          <MdOutlineChecklist />
        </span>
        <h3>{t('landing-page.what-you-need.title')}</h3>
      </div>
      <div className={styles.content}>
        <strong>{t('landing-page.what-you-need.subheading')}</strong>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
        <p onClick={handleClick}>
          <SetInnerHtmlSafe>{whatYouNeedText}</SetInnerHtmlSafe>
        </p>
        {isMobile === true && <p>{t('landing-page.what-you-need.description2.text')}</p>}
      </div>
    </>
  )
}

export default WhatYouNeed
