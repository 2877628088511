import { useTranslation } from 'react-i18next'
import { MdOutlineGrade } from 'react-icons/md'
import styles from './UnlimitedSlotLabel.module.scss'

const UnlimitedSlotLabel = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <span className={styles.icon}>
          <MdOutlineGrade />
        </span>
        {t('collections.available.unlimited-slot')}
      </div>
      <p className={styles.explanation}>{t('collections.available.unlimited-slot-explanation')}</p>
    </div>
  )
}

export default UnlimitedSlotLabel
