import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import pick from 'lodash/pick'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import closeBasket from '@redux/thunks/baskets/closeBasket'
import fetchBasket from '@redux/thunks/baskets/fetchBasket'
import fetchBasketArticle from '@redux/thunks/baskets/fetchBasketArticle'
import { modalKeys, modalTrackingKeys } from 'config/modals'
import Debug from 'helpers/debug'
import { useModal } from 'hooks/application'
import Button from 'components/common/Button'
import {
  ConfirmationModal,
  ModalFooter,
  ModalTitle,
} from 'components/common/Modal/ConfirmationModal'
import UserAvatar from 'components/common/UserAvatar'

const { CLOSE_BASKET_CONFIRMATION } = modalKeys
const debug = Debug('components:basket:confirmation-modal')

const BasketConfirmationModal = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const {
    closeModal,
    data: { basketId },
  } = useModal(CLOSE_BASKET_CONFIRMATION)

  const basket = useAppSelector((state) => state.baskets.items[basketId])
  const basketArticleIds = basket?.basketArticleIds
  const articles = useAppSelector((state) => state.baskets.articles)
  const requesterId = useAppSelector((state) => state.baskets.items[basketId]?.requesterId)
  const { name: userName } = useAppSelector((state) => state.users.items[requesterId])

  useEffect(() => {
    dispatch(fetchBasket(basketId)).catch(debug)
  }, [])

  useEffect(() => {
    if (basketArticleIds !== undefined) {
      basketArticleIds.forEach((id) => {
        dispatch(fetchBasketArticle(id)).catch(debug)
      })
    }
  }, [basketArticleIds])

  const articleQuantity = useMemo(() => {
    if (articles === undefined || basketArticleIds === undefined) {
      return null
    }

    const basketArticles = pick(articles, basketArticleIds)
    const articleValues = Object.values(basketArticles)

    const quantities = articleValues.map((article) => article.quantity)

    const initialReduceValue = 0
    return quantities.reduce((a, b) => a + b, initialReduceValue)
  }, [articles, basketArticleIds])

  const handleCloseBasket = () => {
    dispatch(closeBasket({ basketId }))
    closeModal()
  }

  return (
    <ConfirmationModal
      id="close-basket-confirmation-modal"
      modalKey={CLOSE_BASKET_CONFIRMATION}
    >
      <ModalTitle>{t('baskets.close-basket-confirmation-modal.header')}</ModalTitle>
      <p className="modal-description">
        <span>{t('baskets.close-basket-confirmation-modal.description1')}</span>
        <span className="description-bold">
          {t('baskets.close-basket-confirmation-modal.description2')}
        </span>
        <span>{t('baskets.close-basket-confirmation-modal.description3')}</span>
      </p>
      <div className="requester-information">
        <UserAvatar
          userId={requesterId}
          className="requester-avatar"
        />
        <div>
          <h3>{userName}</h3>
          <p>
            {articleQuantity && (
              <>
                <span>{t('baskets.close-basket-confirmation-modal.assigned-text')}</span>
                <span>{articleQuantity}</span>
                <span>
                  {articleQuantity > 1
                    ? t('baskets.close-basket-confirmation-modal.items-text')
                    : t('baskets.close-basket-confirmation-modal.item-text')}
                </span>
              </>
            )}
          </p>
        </div>
      </div>
      <ModalFooter>
        <Button
          data-tracking={modalTrackingKeys.reject}
          onClick={closeModal}
          secondary
        >
          {t('baskets.close-basket-confirmation-modal.cancel-button')}
        </Button>
        <Button
          data-tracking={modalTrackingKeys.confirm}
          onClick={handleCloseBasket}
        >
          {t('baskets.close-basket-confirmation-modal.accept-button')}
        </Button>
      </ModalFooter>
    </ConfirmationModal>
  )
}

export default BasketConfirmationModal
