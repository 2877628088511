import {
  COLLECTION_FETCH_ERROR,
  COLLECTION_FETCH_START,
  COLLECTION_FETCH_SUCCESS,
} from '@redux/actionTypes'
import { cleanCollection } from '@redux/utilities/handleCollectionData'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { CANCELED_ERROR, loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:fetchCollection')

export default (collectionId) => {
  return (dispatch) => {
    return new Promise<void>((resolve, reject) => {
      const failedFetch = (error) => {
        if (error.name === CANCELED_ERROR) {
          debug(`Cancelled duplicate request`)
          return
        }

        const reason = loggableError(error)
        debug(`Failed fetching collection ${collectionId}`, reason)
        dispatch({ type: COLLECTION_FETCH_ERROR })
        reject(reason)
      }

      dispatch({ type: COLLECTION_FETCH_START })

      return axios
        .get(`/api/v1/collections/${collectionId}`)
        .then((response) => {
          const { data } = response
          dispatch({
            type: COLLECTION_FETCH_SUCCESS,
            payload: cleanCollection(data),
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}
