const cleanBusiness = (business) => {
  return {
    id: business.id,
    name: business.name,
    logo: business.logo,
    notes: business.details,
  }
}

export const cleanBusinesses = (businesses) => {
  return businesses.map(cleanBusiness)
}
