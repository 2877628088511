import { useTranslation } from 'react-i18next'
import { getFieldErrorMessage } from 'helpers/errors'

type Props = {
  id?: string
  error: {
    type: string
    message: string | Record<string, unknown>
  }
}

const FieldError = ({ error, id }: Props) => {
  const { t } = useTranslation()
  const getErrorMessage = () => {
    if (error.message !== '' && error.message !== undefined && error.message !== null) {
      return error.message
    }
    return getFieldErrorMessage({ t, errorType: error.type })
  }

  return (
    <p
      className="error-text"
      aria-live="polite"
      id={id}
    >
      {getErrorMessage()}
    </p>
  )
}

export default FieldError
