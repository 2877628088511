import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { COLLECTIONS_FETCHING_STATUS_SET_IDLE } from '@redux/actionTypes'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { trackEvent } from 'services/Analytics'
import { FETCHING_STATUSES } from 'config/api'
import { AVAILABLE_COLLECTIONS_URL, MY_COLLECTIONS_URL } from 'config/routes'
import { useFetchMyCollectionsWithStoreAndBusinessInfo } from 'hooks/collections'
import Button from 'components/common/Button'
import Card from 'components/common/Cards/Card'
import LoadingSpinner from 'components/common/LoadingSpinner'
import CollectionCard from './CollectionCard'
import styles from './YourCollectionSlotsWidget.module.scss'

const { IDLE, LOADING, SUCCESS } = FETCHING_STATUSES

const YourCollectionSlotsWidget = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { sortedCollectionsWithStoreInfo, fetchingStatus: collectionsFetchingStatus } =
    useFetchMyCollectionsWithStoreAndBusinessInfo()

  const { storesFetchingStatus, businessesFetchingStatus } = useAppSelector((state) => state.stores)

  const noCollections = useMemo(() => {
    return collectionsFetchingStatus === SUCCESS && sortedCollectionsWithStoreInfo.length === 0
  }, [collectionsFetchingStatus, sortedCollectionsWithStoreInfo])

  const showSpinner = useMemo(() => {
    return (
      collectionsFetchingStatus === IDLE ||
      collectionsFetchingStatus === LOADING ||
      storesFetchingStatus === IDLE ||
      storesFetchingStatus === LOADING ||
      businessesFetchingStatus === IDLE ||
      businessesFetchingStatus === LOADING
    )
  }, [collectionsFetchingStatus, storesFetchingStatus, businessesFetchingStatus])

  const slicedCollectionsWithStoreInfo = useMemo(() => {
    if (sortedCollectionsWithStoreInfo.length > 3) {
      return sortedCollectionsWithStoreInfo.slice(0, 3)
    }

    return sortedCollectionsWithStoreInfo
  }, [sortedCollectionsWithStoreInfo])

  const handleRedirectToAvailableCollections = () => {
    dispatch({ type: COLLECTIONS_FETCHING_STATUS_SET_IDLE })
    trackEvent('FWH_DASHBOARD_VIEW_AVAILABLE_COLLECTIONS')
  }

  return (
    <Card className={styles.card}>
      <div className={styles.headingWrapper}>
        <Card.Heading>{t('dashboard-scene.your-collection-slots.header')}</Card.Heading>
      </div>

      {showSpinner === true && noCollections === false && (
        <LoadingSpinner containerHeight="100px" />
      )}

      <motion.div
        layout
        transition={{
          layout: { duration: 0.2 },
        }}
      >
        {showSpinner === false && noCollections === false && (
          <>
            {slicedCollectionsWithStoreInfo.map((collection, index) => {
              return (
                <CollectionCard
                  key={collection.collectionId}
                  collection={collection}
                  index={index}
                />
              )
            })}
            <Link to={MY_COLLECTIONS_URL}>
              <Button
                className={styles.cardButton}
                onClick={() => trackEvent('FWH_DASHBOARD_VIEW_MY_COLLECTIONS')}
              >
                {t('dashboard-scene.your-collection-slots.button-my-collections')}
              </Button>
            </Link>
          </>
        )}

        {noCollections === true && (
          <>
            <p className={styles.noSlotsTitle}>
              {t('dashboard-scene.your-collection-slots.no-slots-header')}
            </p>
            <CollectionCard collection={undefined} />
            <p className={styles.noSlotsDescription}>
              {t('dashboard-scene.your-collection-slots.no-slots-description')}
            </p>
            <Link to={AVAILABLE_COLLECTIONS_URL}>
              <Button
                className={styles.cardButton}
                onClick={handleRedirectToAvailableCollections}
              >
                {t('dashboard-scene.your-collection-slots.button-available-collections')}
              </Button>
            </Link>
          </>
        )}
      </motion.div>
    </Card>
  )
}

export default YourCollectionSlotsWidget
