import {
  MODAL_CLOSE,
  MODAL_OPEN,
  TOGGLE_SIDEBAR,
  FEATURE_FLAGS_FETCH_SUCCESS,
} from '@redux/actionTypes'
import initialState from '@redux/reducers/Application/initialState'
import FEATURE_FLAGS from 'config/featureFlags'

const ACTION_HANDLERS = {
  [MODAL_OPEN]: (state = initialState, action) => {
    const {
      payload: { key, data },
    } = action
    const isModalWithKeyOpen = state.openedModals?.filter((modal) => modal.key === key).length === 1
    if (isModalWithKeyOpen) {
      return state
    }
    return {
      ...state,
      openedModals: [
        ...state.openedModals,
        {
          key,
          data,
        },
      ],
    }
  },
  [MODAL_CLOSE]: (state = initialState, action) => {
    const {
      payload: { key },
    } = action

    return {
      ...state,
      openedModals: state.openedModals.filter((modal) => modal.key !== key),
    }
  },
  [TOGGLE_SIDEBAR]: (state = initialState) => {
    return {
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    }
  },
  [FEATURE_FLAGS_FETCH_SUCCESS]: (state = initialState, action) => {
    const isSimpleSlotLimitsEnabled = action.payload.includes(FEATURE_FLAGS.simpleSlotLimits)
    return {
      ...state,
      featureFlags: {
        simpleSlotLimits: isSimpleSlotLimitsEnabled,
      },
    }
  },
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
