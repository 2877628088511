import styles from './SceneHeader.module.scss'

type Props = {
  title: React.ReactNode
  description?: React.ReactNode
}

const SceneHeader = ({ title, description }: Props) => {
  return (
    <div className={styles.sceneHeader}>
      <h1>{title}</h1>
      {description && <p>{description}</p>}
    </div>
  )
}

export default SceneHeader
