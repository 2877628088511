import { useTranslation } from 'react-i18next'
import { MdOutlineCheckCircle } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { trackEvent } from 'services/Analytics'
import * as routes from 'config/routes'
import Button from 'components/common/Button'
import styles from './InductionCard.module.scss'

type Props = {
  title: string
  description: string
  isComplete: boolean
  inductionId: number
}

const InductionCard = ({ title, description, isComplete, inductionId }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleClick = () => {
    trackEvent('INDUCTION_START', {
      induction_name: title,
      induction_id: inductionId,
      refresh: isComplete,
    })
    if (isComplete) {
      trackEvent('REVIEW_INDUCTION', { induction_name: title, induction_id: inductionId })
    }

    navigate(`${routes.INDUCTIONS_URL}/${inductionId}`, { state: { origin: pathname } })
  }

  return (
    <div className={clsx('card', styles.inductionCard)}>
      <div className={clsx('card-content', styles.cardContent)}>
        <div>
          <h3 className={styles.inductionTitle}>{title}</h3>
          <p className={styles.inductionDescription}>{description}</p>
        </div>
        <span>
          <div className={styles.inductionActions}>
            <Button
              onClick={handleClick}
              secondary
            >
              {isComplete === true
                ? t('inductions.induction.action.view')
                : t('inductions.induction.action.start')}
            </Button>

            {isComplete === true && (
              <div className={styles.isComplete}>
                <MdOutlineCheckCircle />
                <span className={styles.completedText}>
                  {t('inductions.induction.action.completed')}
                </span>
              </div>
            )}
          </div>
        </span>
      </div>
    </div>
  )
}

export default InductionCard
