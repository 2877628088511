import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchBasket from '@redux/thunks/baskets/fetchBasket'
import Debug from 'helpers/debug'
import Article from './Article'

const debug = Debug('components:basket:item')

type Props = {
  basketId: number
}

const Item = ({ basketId }: Props) => {
  const dispatch = useAppDispatch()
  const basket = useAppSelector((state) => state?.baskets?.items[basketId])
  const basketArticleIds = basket?.basketArticleIds
  const requesterId = basket?.requesterId

  useEffect(() => {
    if (undefined === basketArticleIds || undefined === requesterId) {
      dispatch(fetchBasket(basketId)).catch(debug)
    }
  }, [])

  return (
    <>
      {basketArticleIds &&
        basketArticleIds.map((id) => (
          <Article
            key={id}
            basketArticleId={id}
          />
        ))}
    </>
  )
}

export default Item
