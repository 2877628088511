import clsx from 'clsx'
import styles from './Card.module.scss'

type CardType = {
  Element?: keyof JSX.IntrinsicElements
  className?: string
  children: React.ReactNode
}

type CardHeadingType = {
  className?: string
  children: React.ReactNode
}

type CardImageType = {
  className?: string
  children: string
}

type CardTextSectionType = {
  className?: string
  children: React.ReactNode
}

const Card = ({ Element = 'article', className, children }: CardType) => {
  return (
    <Element className={clsx(className, styles.card)}>
      <div className={clsx(styles.content)}>{children}</div>
    </Element>
  )
}

const CardHeading = ({ className, children }: CardHeadingType) => (
  <h3 className={className}>{children}</h3>
)
const CardImage = ({ className, children }: CardImageType) => (
  <div className={clsx(className, styles.imageWrapper)}>
    <img
      alt=""
      src={children}
    />
  </div>
)
const CardTextSection = ({ className, children }: CardTextSectionType) => (
  <div className={clsx(className, styles.textSection)}>{children}</div>
)

Card.Heading = CardHeading
Card.Image = CardImage
Card.TextSection = CardTextSection

export default Card
