import LandingPageImageDesktop from 'images/LandingPageDesktopHeader.png'
import LandingPageImageDesktopWebp from 'images/LandingPageDesktopHeader.webp'
import LandingPageImageMobile from 'images/LandingPageMobileHeader.png'
import LandingPageImageMobileWebp from 'images/LandingPageMobileHeader.webp'
import BecomeAFoodWasteHeroImageDesktop from 'images/become-a-food-waste-hero-desktop.png'
import BecomeAFoodWasteHeroImageDesktopWebp from 'images/become-a-food-waste-hero-desktop.webp'
import BecomeAFoodWasteHeroImageMobile from 'images/become-a-food-waste-hero-mobile.png'
import BecomeAFoodWasteHeroImageMobileWebp from 'images/become-a-food-waste-hero-mobile.webp'

export const BECOME_A_FOOD_HERO = 'become-food-waste-hero'
export const LANDING_PAGE = 'landing-page'

export const getPictureHeaderConfig = (t) => {
  return {
    [BECOME_A_FOOD_HERO]: {
      mobileWebp: BecomeAFoodWasteHeroImageMobileWebp,
      mobile: BecomeAFoodWasteHeroImageMobile,
      desktopWebp: BecomeAFoodWasteHeroImageDesktopWebp,
      desktop: BecomeAFoodWasteHeroImageDesktop,
      altText: t('join_intro_page.image_alt_text'),
    },
    [LANDING_PAGE]: {
      mobileWebp: LandingPageImageMobileWebp,
      mobile: LandingPageImageMobile,
      desktopWebp: LandingPageImageDesktopWebp,
      desktop: LandingPageImageDesktop,
      altText: t('landing-page.image-alt-text'),
    },
  }
}
