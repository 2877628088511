import { IconType } from 'react-icons'
import clsx from 'clsx'
import styles from './InformationIcon.module.scss'

type Props = {
  Icon: IconType
  collectionType: string
  testId?: string
}

const InformationIcon = ({ Icon, collectionType, testId }: Props) => {
  return (
    <Icon
      className={clsx(styles.icon, styles[collectionType])}
      data-testid={testId}
    />
  )
}

export default InformationIcon
