export const getReportAnIssueUrl = ({
  t,
  storeName,
  businessName,
  storeId,
  businessId,
  userId,
}) => {
  const baseUrl = t('common.something_wrong_url')

  if (
    storeName !== undefined &&
    businessName !== undefined &&
    storeId !== undefined &&
    businessId !== undefined &&
    userId !== undefined
  ) {
    const urlEncodedStoreName = encodeURIComponent(storeName)
    const urlEncodedBusinessName = encodeURIComponent(businessName)
    const uniqueId = `${Date.now()}${Math.floor(Math.random() * 1000)}`
    return (
      `${baseUrl}?unique_id=${uniqueId}` +
      `&store_name=${urlEncodedStoreName}` +
      `&business_name=${urlEncodedBusinessName}` +
      `&store_id=${storeId}` +
      `&business_id=${businessId}` +
      `&user_id=${userId}` +
      '&referrer=volunteer-hub'
    )
  }

  return baseUrl
}

export const appendUniqueIdAndReferrerToTypeformUrl = (url: string): string => {
  const uniqueId = `${Date.now()}${Math.floor(Math.random() * 1000)}`
  const newUrl = `${url}?unique_id=${uniqueId}&referrer=volunteer-hub`
  return newUrl
}
