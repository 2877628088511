import DOMPurify from 'dompurify'

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener')
  }
})

type Props = {
  children: React.ReactNode
}

const SetInnerHtmlSafe = ({ children }: Props) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(children),
      }}
    />
  )
}

export default SetInnerHtmlSafe
