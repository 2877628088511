type Props = {
  x?: string
  y?: string
}

const Spacer = ({ x = '0', y = '0' }: Props) => {
  return <div style={{ height: x, width: y }} />
}

export default Spacer
