import { useTranslation } from 'react-i18next'
import Navigation from 'components/layout/Navigation'
import styles from './ErrorBoundary.module.scss'

const ErrorBoundary = () => {
  const { t } = useTranslation()

  return (
    <>
      <Navigation />
      <div className={styles.wrapper}>
        <h1>{t('error-boundary.title')}</h1>
        <p>{t('error-boundary.message')}</p>
      </div>
    </>
  )
}

export default ErrorBoundary
