import { Navigate, useLocation } from 'react-router-dom'
import { LOGIN_URL, LOW_RATING_ERROR_URL } from 'config/routes'
import { useAuthenticatedUser, useIsUserAuthenticated } from 'hooks/users'

type Props = {
  children: React.ReactNode
}

const RequireAuthentication = ({ children }: Props) => {
  const location = useLocation()
  const { hasLowRating } = useAuthenticatedUser({ shouldFetchUser: true })
  const isUserAuthenticated = useIsUserAuthenticated()

  if (isUserAuthenticated === false) {
    return (
      <Navigate
        to={LOGIN_URL}
        state={{ from: location }}
        replace
      />
    )
  }

  if (hasLowRating === true) {
    return (
      <Navigate
        to={LOW_RATING_ERROR_URL}
        replace
      />
    )
  }

  return children
}

export default RequireAuthentication
