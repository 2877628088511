import { ACCOUNT_BASKET_IDS_FETCH_SUCCESS } from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { CANCELED_ERROR, loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:baskets:fetchAccountBaskets')

export default () => {
  return (dispatch) => {
    return new Promise<void>((resolve, reject) => {
      const failedFetch = (error) => {
        if (error.name === CANCELED_ERROR) {
          debug(`Cancelled duplicate request`)
          return
        }

        const reason = loggableError(error)
        debug('Failed fetching account baskets', reason)
        reject(reason)
      }

      return axios
        .get('/api/v1/baskets')
        .then((response) => {
          const { data } = response
          dispatch({
            type: ACCOUNT_BASKET_IDS_FETCH_SUCCESS,
            payload: {
              baskets: data,
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}
