import { MdOutlinePlace } from 'react-icons/md'
import InformationIcon from './InformationIcon'
import styles from './Location.module.scss'

type Props = {
  storeAddress: StoreAddressType
  type: string
}

const Location = ({ storeAddress, type }: Props) => {
  return (
    <div className={styles.root}>
      <InformationIcon
        testId="locationIcon"
        Icon={MdOutlinePlace}
        collectionType={type}
      />
      <address className={styles.storeAddress}>
        {storeAddress &&
          Object.values(storeAddress).map((addressLine, index) => {
            return <span key={index}>{addressLine}</span>
          })}
      </address>
    </div>
  )
}

export default Location
