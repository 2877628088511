import {
  ACCOUNT_BASKET_IDS_FETCH_SUCCESS,
  AUTHENTICATE_USER_ERROR,
  AUTHENTICATE_USER_SUCCESS,
  CLEAR_AUTHENTICATION_ERROR_MESSAGE,
  CURRENT_USER_FETCH_ERROR,
  CURRENT_USER_FETCH_SUCCESS,
  USER_ACTIVITY_FETCH_SUCCESS,
  USER_ADDRESS_FETCH_SUCCESS,
  USER_DATA_HYDRATE,
  USER_FETCH_FAIL,
  USER_FETCH_SUCCESS,
} from '@redux/actionTypes'
import initialState from '@redux/reducers/Users/initialState'
import { FETCHING_STATUSES } from 'config/api'
import { getCurrentUserId } from 'helpers/user'

const ACTION_HANDLERS = {
  [AUTHENTICATE_USER_SUCCESS]: (state = initialState, { payload }) => {
    return {
      ...state,
      currentUser: {
        id: payload.id,
      },
      authenticationError: undefined,
    }
  },
  [AUTHENTICATE_USER_ERROR]: (state = initialState, { payload }) => {
    return {
      ...state,
      currentUser: {
        id: undefined,
      },
      authenticationError: payload.errorType,
    }
  },
  [CLEAR_AUTHENTICATION_ERROR_MESSAGE]: (state = initialState) => {
    return {
      ...state,
      authenticationError: undefined,
    }
  },
  [ACCOUNT_BASKET_IDS_FETCH_SUCCESS]: (state = initialState, action) => {
    const { payload } = action
    const { baskets } = payload

    const newItems = {}

    baskets.forEach((basket) => {
      newItems[basket.requester_id] = {
        ...state.items[basket.requester_id],
        basketId: basket.id,
      }
    })

    return {
      ...state,
      items: {
        ...state.items,
        ...newItems,
      },
    }
  },
  [USER_FETCH_SUCCESS]: (state = initialState, action) => {
    const { payload } = action
    const { userId, name, avatar, rating, location, volunteerActivation } = payload

    return {
      ...state,
      items: {
        ...state.items,
        [userId]: {
          ...state.items[userId],
          id: userId,
          name,
          avatar,
          rating,
          location,
          volunteerActivation,
        },
      },
    }
  },
  [USER_FETCH_FAIL]: (state = initialState) => {
    return {
      ...state,
      currentUser: {
        id: undefined,
      },
    }
  },
  [USER_ADDRESS_FETCH_SUCCESS]: (state = initialState, action) => {
    const {
      userId,
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      city,
      county,
      country,
      postcode,
      telephoneNumber,
    } = action.payload
    return {
      ...state,
      items: {
        ...state.items,
        [userId]: {
          ...state.items[userId],
          addressLine1,
          addressLine2,
          addressLine3,
          addressLine4,
          city,
          county,
          country,
          postcode,
          telephoneNumber,
        },
      },
    }
  },
  [USER_ACTIVITY_FETCH_SUCCESS]: (state = initialState, { payload }) => {
    const { totalArticles, totalCollections, articlesLast30Days } = payload
    const currentUserId = getCurrentUserId()

    return {
      ...state,
      items: {
        ...state.items,
        [currentUserId]: {
          ...state.items[currentUserId],
          activity: {
            totalArticles,
            totalCollections,
            articlesLast30Days,
          },
        },
      },
    }
  },
  [CURRENT_USER_FETCH_SUCCESS]: (state = initialState, { payload }) => {
    const { currentUser } = payload

    return {
      ...state,
      currentUser,
      currentUserFetchingStatus: FETCHING_STATUSES.SUCCESS,
    }
  },
  [CURRENT_USER_FETCH_ERROR]: (state = initialState) => {
    return {
      ...state,
      currentUserFetchingStatus: FETCHING_STATUSES.FAILED,
    }
  },
  [USER_DATA_HYDRATE]: (state = initialState, { payload }) => {
    return {
      ...state,
      currentUser: {
        id: payload.userId,
        roles: payload.userRoles,
        verified: payload.userIsVerified,
        rating: payload.userRating,
      },
    }
  },
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
