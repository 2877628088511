import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useAppDispatch } from '@redux/hooks'
import fetchCurrentUser from '@redux/thunks/users/fetchCurrentUser'
import Debug from 'helpers/debug'

const debug = Debug('components:global-data-fetcher')

const GlobalDataFetcher = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCurrentUser()).catch(debug)
  }, [])

  return <Outlet />
}

export default GlobalDataFetcher
