import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { getPictureHeaderConfig } from 'config/pictureHeader'
import styles from './PictureHeader.module.scss'

type Props = {
  variant: string
}

const PictureHeader = ({ variant }: Props) => {
  const { t } = useTranslation()

  const pictureHeaderConfig = useMemo(() => {
    return getPictureHeaderConfig(t)
  }, [])

  return (
    <picture>
      <source
        media="(max-width: 400px)"
        type="image/webp"
        srcSet={pictureHeaderConfig[variant].mobileWebp}
        className={styles[variant]}
      />
      <source
        media="(max-width: 400px)"
        srcSet={pictureHeaderConfig[variant].mobile}
      />
      <source
        media="(min-width: 400px)"
        type="image/webp"
        srcSet={pictureHeaderConfig[variant].desktopWebp}
        className={styles[variant]}
      />
      <source
        media="(min-width: 400px)"
        srcSet={pictureHeaderConfig[variant].desktop}
        className={styles[variant]}
      />
      <img
        className={clsx(styles.image, styles[variant])}
        alt={pictureHeaderConfig[variant].altText}
        src={pictureHeaderConfig[variant].mobile}
      />
    </picture>
  )
}

export default PictureHeader
