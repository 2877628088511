import { useTranslation } from 'react-i18next'
import { convertDistance, getDistance } from 'geolib'
import { getMapUrl } from 'helpers/map'
import styles from './Distance.module.scss'

type CollectionDistanceType = {
  units: string
  baseLocation: {
    latitude: number
    longitude: number
  }
  destinationLocation: {
    latitude: number
    longitude: number
  }
}
const CollectionDistance = ({
  units,
  baseLocation,
  destinationLocation,
}: CollectionDistanceType) => {
  const { t } = useTranslation()

  if (!baseLocation || !destinationLocation) {
    return null
  }

  const mapUrl = getMapUrl(destinationLocation)
  const distanceInMeters = getDistance(baseLocation, destinationLocation)
  const distanceInUserUnits = convertDistance(distanceInMeters, units).toFixed(1)

  return (
    <div className={styles.root}>
      <div className={styles.collectionDistance}>
        <span
          dangerouslySetInnerHTML={{
            __html: t('collection_slots.partials.slot.distance_from_your_location', {
              distance: t('collection_slots.partials.slot.distance', {
                quantity: distanceInUserUnits,
                units: t(`common.units-short.${units}`),
              }),
              mapUrl,
            }),
          }}
        />
      </div>
    </div>
  )
}

export default CollectionDistance
