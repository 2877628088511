import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import Requester from 'components/Baskets/List/Header/Requester'

type Props = {
  section: {
    id: number
  }
  active: boolean
}

const Header = ({ section, active }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="card-header">
      <Requester basketId={section.id} />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="card-header-content-label">
        {active === true
          ? t('baskets.content-button.collapse')
          : t('baskets.content-button.expand')}
      </a>
    </div>
  )
}

export default memo(Header)
