export const DESKTOP_BREAKPOINT = 992

export const isWideScreen = () => {
  const width = window.innerWidth
  return width >= DESKTOP_BREAKPOINT
}

/**
 * This function is required for postcss-viewport-height-correction to work
 * It fixes a common issue where 100vh on mobile devices doesn't take into account the address bar
 * https://github.com/Faisal-Manzer/postcss-viewport-height-correction
 */
export const viewportHeightCorrection = () => {
  const customViewportCorrectionVariable = 'vh'

  const setViewportProperty = (doc) => {
    let previousClientHeight
    const customViewportHeightProperty = '--' + (customViewportCorrectionVariable || 'vh')
    const handleResize = () => {
      const clientHeight = doc.clientHeight
      if (clientHeight === previousClientHeight) return
      requestAnimationFrame(function updateViewportHeight() {
        doc.style.setProperty(customViewportHeightProperty, clientHeight * 0.01 + 'px')
        previousClientHeight = clientHeight
      })
    }
    handleResize()
    return handleResize
  }
  window.addEventListener('resize', setViewportProperty(document.documentElement))
}
