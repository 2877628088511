import { Navigate, useLocation } from 'react-router-dom'
import { DASHBOARD_URL, LOGIN_URL } from 'config/routes'
import { useAuthenticatedUser, useIsUserAuthenticated } from 'hooks/users'

type Props = {
  children: React.ReactNode
}

const RequireNonFoodHeroAccess = ({ children }: Props) => {
  const location = useLocation()
  const isUserAuthenticated = useIsUserAuthenticated()

  const { isFoodHero, isUserLoaded } = useAuthenticatedUser({ shouldFetchUser: true })
  if (isUserAuthenticated === false) {
    return (
      <Navigate
        to={`${LOGIN_URL}${location.search}`}
        state={{ from: location }}
        replace
      />
    )
  }

  if (isUserLoaded === false) {
    return null
  }

  if (isFoodHero === true) {
    return (
      <Navigate
        to={DASHBOARD_URL}
        replace
      />
    )
  }

  return children
}

export default RequireNonFoodHeroAccess
