import { useAccordion } from 'hooks/application'
import StoreCard from 'components/Stores/StoreCard'

type Props = {
  storeIds: string[]
}

const StoreList = ({ storeIds }: Props) => {
  const { handleExpandClick, handleExpandKeyDown, isExpanded } = useAccordion({
    autoExpandedSections: [],
    expandMultiple: false,
  })

  return (
    <>
      {storeIds.map((storeId) => {
        return (
          <StoreCard
            storeId={Number(storeId)}
            key={storeId}
            handleExpandClick={handleExpandClick}
            handleExpandKeyDown={handleExpandKeyDown}
            isExpanded={isExpanded}
          />
        )
      })}
    </>
  )
}

export default StoreList
