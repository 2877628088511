import { memo } from 'react'
import { modalKeys } from 'config/modals'
import { COLLECTION_ACTIONS } from 'helpers/collections'
import { useModal } from 'hooks/application'
import ActionsModal from './ActionsModal'

type Props = {
  collectionId: number
}

const UnassignModal = ({ collectionId }: Props) => {
  const { isOpen } = useModal(modalKeys.UNASSIGN_COLLECTION)

  if (isOpen === false) {
    return null
  }

  return (
    <ActionsModal
      collectionId={collectionId}
      mode={COLLECTION_ACTIONS.UNASSIGN}
    />
  )
}

export default memo(UnassignModal)
