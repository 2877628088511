import clsx from 'clsx'
import styles from './DocumentCard.module.scss'

type Props = {
  title: string
  description: string
  link: string
  icon?: React.ReactNode
}

const DocumentCard = ({ title, description, link, icon }: Props) => {
  return (
    <div className={clsx(styles.documentCard, 'card')}>
      <div className={clsx('card-content', styles.content)}>
        <div>
          <h3
            className={styles.title}
            data-testid="document-title"
          >
            {title}
          </h3>
          <div className={styles.description}>{description}</div>
        </div>
        <span className={styles.linkAndIcon}>
          {icon && <span className={styles.icon}>{icon}</span>}
          <a
            href={link}
            className={styles.link}
          >
            {title}
          </a>
        </span>
      </div>
    </div>
  )
}

export default DocumentCard
