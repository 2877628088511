import i18next from 'i18next'
import Env from 'helpers/environment'

export const getHelpScoutProviderKey = () => {
  switch (i18next.language) {
    case 'en':
      return Env.PUBLIC_HELPSCOUT_API_KEY_EN
    case 'es':
      return Env.PUBLIC_HELPSCOUT_API_KEY_ES
    default:
      return Env.PUBLIC_HELPSCOUT_API_KEY
  }
}
