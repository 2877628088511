import { sanitiseAddress } from 'helpers/stores'

export const cleanStores = (stores) => {
  const cleanedStores = stores.map((store) => {
    const {
      business_id: businessId,
      name,
      address_line_1,
      address_line_2,
      address_line_3,
      postal_code,
      id,
      details,
      timezone,
      latitude,
      longitude,
      squad_captain_id,
    } = store

    return {
      businessId,
      name,
      address: sanitiseAddress({
        addressLine1: address_line_1,
        addressLine2: address_line_2,
        addressLine3: address_line_3,
        postalCode: postal_code,
      }),
      storeId: id,
      storeNotes: details,
      timezone,
      location: {
        latitude,
        longitude,
      },
      squadCaptain: {
        id: squad_captain_id,
      },
    }
  })

  return cleanedStores
}
