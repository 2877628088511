import { MdOutlinePlace } from 'react-icons/md'
import clsx from 'clsx'
import styles from './UserInfo.module.scss'

type Props = {
  avatar: string
  name: string
  location: string
}

const SidebarUserInfo = ({ avatar, name, location }: Props) => {
  return (
    <div className="center-align">
      <div className={styles.userAvatar}>
        <img
          alt=""
          className="circle"
          src={avatar}
        />
      </div>
      <div className={styles.details}>
        <strong>{name}</strong>
        <span className={clsx(styles.iconAndTitle, styles.userLocation)}>
          <span className={styles.icon}>
            <MdOutlinePlace data-testid="locationIcon" />
          </span>
          <p>{location}</p>
        </span>
      </div>
    </div>
  )
}

export default SidebarUserInfo
