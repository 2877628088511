import clsx from 'clsx'
import styles from './ActivityItem.module.scss'

type Props = {
  icon: React.ReactNode
  name: string
  value: number
  description: string
}

const ActivityItem = ({ icon, name, value, description }: Props) => {
  return (
    <article className={clsx(styles.container)}>
      <span className={styles.icon}>{icon}</span>
      <div>
        <span className={styles.name}>{name}</span>
        <span className={styles.value}>{value}</span>
        <span className={styles.description}>{description}</span>
      </div>
    </article>
  )
}

export default ActivityItem
