import { useAppSelector } from '@redux/hooks'
import { selectMyStoresWithBusinesses } from '@redux/selectors/stores'
import { FETCHING_STATUSES } from 'config/api'

const { IDLE, LOADING } = FETCHING_STATUSES

const useMyStores = () => {
  const stores = useAppSelector((state) => selectMyStoresWithBusinesses(state))

  const isLoadingStores = useAppSelector(
    (state) =>
      state.stores.storesFetchingStatus === IDLE || state.stores.storesFetchingStatus === LOADING,
  )

  const isLoadingBusinesses = useAppSelector(
    (state) => state.stores.businessesFetchingStatus === LOADING,
  )

  return {
    data: stores,
    isLoading: isLoadingStores || isLoadingBusinesses,
  }
}

export default useMyStores
