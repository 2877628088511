const sanitiseAddress = (address: any) => {
  if (address === undefined) {
    return undefined
  }

  const addressObj = {}

  if (Object.keys(address).length === 0) {
    return {}
  }

  for (const index in address) {
    if (address[index] !== '') {
      addressObj[index] = address[index]
    }
  }
  return addressObj
}

export { sanitiseAddress }
