import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchAccountBaskets from '@redux/thunks/baskets/fetchAccountBaskets'
import { BASKET_CLOSE as ANIMATION_OPTIONS } from 'helpers/animations'
import Debug from 'helpers/debug'
import { SECOND_IN_MILLISECONDS } from 'helpers/time'
import EmptyBasketIcon from 'images/Bread.svg'
import AnimationWrapper from 'components/Baskets/List/AnimationWrapper'
import Content from 'components/Baskets/List/Content'
import Footer from 'components/Baskets/List/Footer'
import Header from 'components/Baskets/List/Header'
import Accordion from 'components/common/Accordion'
import InfoItem, { infoItemTypes } from 'components/common/InfoItem'

const { EMPTY } = infoItemTypes
const debug = Debug('components:basket:list')
let emptyBasketInfoTimeout

type Props = {
  requesterId?: number
}

const List = ({ requesterId }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [hasLoaded, setHasLoaded] = useState(false)
  const [showEmptyBasketInfo, setShowEmptyBasketInfo] = useState(false)
  const basketIds = useAppSelector((state) => state.baskets?.userBaskets)
  const requesterBasketId = useAppSelector((state) => state.users?.items[requesterId]?.basketId)
  const originBasketIds = requesterBasketId === undefined ? [] : [requesterBasketId]
  const sections = basketIds.map((basketId) => ({ id: basketId }))

  useEffect(() => {
    const callAsyncFetch = async () => {
      try {
        await dispatch(fetchAccountBaskets())
        setHasLoaded(true)
      } catch (error) {
        debug(error)
      }
    }
    callAsyncFetch()
  }, [])

  useEffect(() => {
    if (basketIds.length === 0) {
      emptyBasketInfoTimeout = setTimeout(() => {
        setShowEmptyBasketInfo(true)
      }, ANIMATION_OPTIONS.duration * SECOND_IN_MILLISECONDS)
    } else {
      clearTimeout(emptyBasketInfoTimeout)
      setShowEmptyBasketInfo(false)
    }
  }, [basketIds.length])

  if (hasLoaded === false) {
    return null
  }

  return (
    <>
      {showEmptyBasketInfo === true && (
        <motion.div
          transition={{ duration: ANIMATION_OPTIONS.duration }}
          variants={ANIMATION_OPTIONS.variants}
          animate="visible"
          initial="exit"
        >
          <InfoItem
            type={EMPTY}
            title={t('baskets.empty-state.title')}
            description1={t('baskets.empty-state.description1')}
            description2={t('baskets.empty-state.description2')}
            icon={EmptyBasketIcon}
          />
        </motion.div>
      )}
      <Accordion
        sections={sections}
        Header={Header}
        Content={Content}
        Footer={Footer}
        ItemWrapper={AnimationWrapper}
        AccordionWrapper={AnimatePresence}
        autoExpandedSections={originBasketIds}
      />
    </>
  )
}

export default memo(List)
