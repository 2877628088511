import Env from 'helpers/environment'

export const BASKETS_URL = '/baskets'
export const DASHBOARD_URL = '/dashboard'
export const JOIN_INTRO_URL = '/join-intro'
export const STORES_URL = '/stores'
export const MY_COLLECTIONS_URL = '/my-collections'
export const INDUCTIONS_URL = '/inductions'
export const NOTIFICATIONS_URL = '/notification-settings'
export const DOCUMENTS_URL = '/documents'
export const WEBAPP_URL = Env.PUBLIC_NATIVE_APP_HOST
export const FORGOT_PASSWORD_URL = 'forgot-password'
export const FAQ_URL = '/frequently-asked-questions'
export const REGISTER_URL = '/register'
export const LOGIN_URL = '/login'
export const LOGIN_MAGIC_URL = '/login/magic'
export const LOGIN_EMAIL_REQUESTED_URL = '/magic_login_sent'
export const LOGOUT_URL = '/logout'
export const LOW_RATING_ERROR_URL = '/access-error/low-rating'
export const AVAILABLE_COLLECTIONS_URL = '/available-collections'
export const ERROR_URL = '/error'
export const ACCESS_ERROR_URL = '/access-error'
export const LANDING_PAGE_URL = '/'

export const DASHBOARD = 'dashboard'
export const LANDING_PAGE = 'landing_page'
export const APP_NOT_WORKING = 'app_not_working'
export const JOIN_INTRO = 'join_intro'
export const STORES = 'stores'
export const MY_COLLECTIONS = 'my_collections'
export const INDUCTIONS = 'inductions'
export const NOTIFICATIONS = 'notification_settings'
export const DOCUMENTS = 'documents'
export const ADMIN = 'admin'
export const FAQ = 'faqs'
export const LOGOUT = 'logout'
export const AVAILABLE_COLLECTIONS = 'available_collections'
export const BASKETS = 'baskets'

export const getSidebarLinks = (t) => {
  return {
    [DASHBOARD]: {
      url: DASHBOARD_URL,
      text: t('layouts.sidebar.dashboard'),
    },
    [APP_NOT_WORKING]: {
      url: t('layouts.sidebar.app_not_working_url'),
      text: t('layouts.sidebar.app_not_working'),
    },
    [JOIN_INTRO]: {
      url: JOIN_INTRO_URL,
      text: t('layouts.sidebar.join'),
    },
    [STORES]: {
      url: STORES_URL,
      text: t('layouts.sidebar.stores'),
    },
    [MY_COLLECTIONS]: {
      url: MY_COLLECTIONS_URL,
      text: t('layouts.sidebar.my_collections'),
    },
    [AVAILABLE_COLLECTIONS]: {
      url: AVAILABLE_COLLECTIONS_URL,
      text: t('layouts.sidebar.available_collections'),
    },
    [INDUCTIONS]: {
      url: INDUCTIONS_URL,
      text: t('layouts.sidebar.inductions'),
    },
    [NOTIFICATIONS]: {
      url: NOTIFICATIONS_URL,
      text: t('layouts.sidebar.notification_settings'),
    },
    [DOCUMENTS]: {
      url: DOCUMENTS_URL,
      text: t('layouts.sidebar.documents'),
    },
    [FAQ]: {
      url: FAQ_URL,
      text: t('layouts.sidebar.faqs'),
    },
    [LOGOUT]: {
      url: LOGOUT_URL,
      text: t('layouts.sidebar.logout'),
    },
    [BASKETS]: {
      url: BASKETS_URL,
      text: t('layouts.sidebar.baskets'),
    },
  }
}
