import { useState } from 'react'

type Props = {
  collapsedToggle: React.ReactNode
  expandedToggle: React.ReactNode
  children: React.ReactNode
}

const Collapsible = ({ collapsedToggle, expandedToggle, children }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (isExpanded === true) {
    return (
      <>
        <div>{children}</div>
        <button
          className="no-button-styling"
          type="button"
          onClick={() => setIsExpanded(false)}
        >
          {expandedToggle}
        </button>
      </>
    )
  }

  return (
    <>
      <button
        className="no-button-styling"
        type="button"
        onClick={() => setIsExpanded(true)}
      >
        {collapsedToggle}
      </button>
    </>
  )
}

export default Collapsible
