import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineContactSupport } from 'react-icons/md'
import clsx from 'clsx'
import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import styles from './AboutInduction.module.scss'

type Props = {
  handleStartQuizClick: () => void
  questionCount: number
}

const AboutInduction = ({ handleStartQuizClick, questionCount }: Props) => {
  const { t } = useTranslation()
  const [showStartQuizButton, setShowStartQuizButton] = useState(false)

  return (
    <div className={clsx('card', styles.aboutInductionCard)}>
      <h2>{t('inductions.page.show.about_this_induction.title')}</h2>
      <div className={styles.aboutInductionQuestionNumber}>
        <MdOutlineContactSupport
          className={styles.icon}
          data-testid="contact-support-icon"
        />
        <span>
          {t('inductions.page.show.about_this_induction.question_count', {
            count: questionCount,
          })}
        </span>
      </div>
      <p>{t('inductions.page.show.about_this_induction.description')}</p>
      <Checkbox
        checked={showStartQuizButton}
        id="confirm-watched-video-checkbox"
        label={t('inductions.page.show.about_this_induction.checkbox_label')}
        onChange={() => setShowStartQuizButton((value) => !value)}
      />

      {showStartQuizButton === true && (
        <Button
          className={styles.aboutInductionStartQuiz}
          onClick={handleStartQuizClick}
        >
          {t('inductions.page.show.button-start')}
        </Button>
      )}
    </div>
  )
}

export default AboutInduction
