import clsx from 'clsx'

export const VARIANTS = {
  PINK: 'pink',
}

type Props = {
  variant?: string
  children: React.ReactNode
}

const Pill = ({ variant, children }: Props) => {
  return <div className={clsx(variant, 'pill')}>{children}</div>
}

Pill.Icon = function PillIcon({ children }) {
  return <div className="pill-icon">{children}</div>
}

Pill.Text = function PillText({ children }) {
  return <div className="pill-text">{children}</div>
}

export default Pill
