import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:inductions:completeInduction')

const completeInduction = createAsyncThunk(
  'inductions/completeInduction',
  async (inductionId: number, thunkAPI: any): Promise<any> => {
    const inductionTitle = thunkAPI.getState().inductions.items[inductionId].title
    try {
      await axios.patch(`/api/v1/inductions/${inductionId}/complete`)
      return {
        id: inductionId,
        meta: {
          analytics: {
            eventName: 'INDUCTION_QUIZ_SUCCESS',
            eventPayload: {
              inductionName: inductionTitle,
              inductionId: inductionId,
            },
          },
        },
      }
    } catch (error) {
      debug(`Failed completing induction ${inductionId}`, loggableError(error))
      return thunkAPI.rejectWithValue(error.response.status)
    }
  },
)

export default completeInduction
