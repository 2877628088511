import { BASKET_CLOSED_SUCCESS } from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:baskets:closeBasket')

const closeBasket = (data) => {
  const { basketId } = data
  return (dispatch) => {
    return new Promise<void>((resolve, reject) => {
      const failedFetch = (error) => {
        const reason = loggableError(error)
        debug(`Failed closing basket ${basketId}`, reason)
        reject(reason)
      }

      return axios
        .put(`/api/v1/baskets/${basketId}/close`)
        .then(() => {
          dispatch({
            type: BASKET_CLOSED_SUCCESS,
            payload: {
              basketId,
            },
            meta: {
              analytics: {
                eventName: BASKET_CLOSED_SUCCESS,
                eventPayload: {
                  basketId,
                },
              },
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}

export default closeBasket
