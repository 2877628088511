import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@redux/hooks'
import { getCollectionActionDetails } from 'helpers/collections'
import StepContent from './StepContent'
import StepHeader from './StepHeader'

type Props = {
  step?: string
  mode?: string
}

const Step = ({ step, mode }: Props) => {
  const { t } = useTranslation()
  const activeStep = useAppSelector((state) => state.collections[mode].step)

  const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
    return getCollectionActionDetails(t)
  }, [])
  const isStepComplete = () =>
    COLLECTION_ACTIONS_DETAILS[mode].steps[activeStep].position >
    COLLECTION_ACTIONS_DETAILS[mode].steps[step].position

  return (
    <div className={`step${isStepComplete() ? ' complete' : ''}`}>
      <StepHeader
        step={step}
        mode={mode}
      />
      <StepContent
        step={step}
        mode={mode}
      />
    </div>
  )
}

export default Step
