import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useAppSelector } from '@redux/hooks'
import IconOlioLogo from 'images/olio-logo-header.svg'
import MyAvatar from 'components/common/MyAvatar'
import styles from './IdCard.module.scss'

const IdCard = () => {
  const { t } = useTranslation()
  const userName = useAppSelector((state) => state.users.currentUser?.name?.firstName)

  return (
    <div className={clsx('card', styles.root)}>
      <div className="card-stacked">
        <div className={styles.header}>
          <img
            alt=""
            src={IconOlioLogo}
            data-testid="logo"
          />
        </div>
        <div>
          <div className={styles.content}>
            <MyAvatar className={styles.avatar} />
            <div className={styles.userDetails}>
              <h3>{userName}</h3>
              <strong>{t('your-collections.id-card.title')}</strong>
              <p>{t('your-collections.id-card.role')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdCard
