import clsx from 'clsx'
import styles from './Label.module.scss'

type LabelVariantType = 'standard' | 'green'

type LabelType = {
  className?: string
  icon: React.ReactNode
  text: string
  variant?: LabelVariantType
}

const Label: React.FC<LabelType> = ({ text, icon, className, variant = 'standard' }) => {
  return (
    <div className={clsx(styles.label, styles[variant], className)}>
      {icon && <span className={styles.labelIcon}>{icon}</span>}
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default Label
