import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { trackEvent } from 'services/Analytics'
import { modalTrackingEvents } from 'config/modals'
import { useModal } from 'hooks/application'
import GenericModal from 'components/common/Modal/GenericModal'
import styles from './ConfirmationModal.module.scss'

type ConfirmationModalProps = {
  className?: string
  id?: string
  modalKey: string
  actionOnClose?: () => void
  children: React.ReactNode
}

const ConfirmationModal = ({
  className,
  id,
  modalKey,
  actionOnClose,
  children,
}: ConfirmationModalProps) => {
  const { closeModal } = useModal(modalKey, { actionOnClose })

  const handleDismissClick = () => {
    trackEvent('MODAL_DISMISS', { modalKey })
    closeModal()
  }

  return (
    <GenericModal
      closeModal={closeModal}
      className={clsx(className, styles.confirmationModal)}
      id={id}
      modalKey={modalKey}
    >
      <div className={styles.confirmationModalUpperSection}>
        <div className={styles.confirmationModalHeader}>
          <button
            className={clsx('no-button-styling', styles.icon)}
            onClick={handleDismissClick}
            type="button"
          >
            <MdOutlineCancel data-testid="closeIcon" />
          </button>
        </div>

        {children}
      </div>
    </GenericModal>
  )
}

type ModalTitleProps = {
  children: React.ReactNode
}

const ModalTitle = ({ children }: ModalTitleProps) => {
  return <h2 className={styles.modalTitle}>{children}</h2>
}
type ModalFooterProps = {
  children: React.ReactNode
}

const ModalFooter = ({ children }: ModalFooterProps) => {
  const openedModal = useSelector(({ application }) => application.openedModals.find(Boolean))
  const { key: modalKey } = openedModal

  const childrenWithTracking = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement, {
        onClick: () => {
          const trackingKey = child.props['data-tracking']
          if (Object.keys(modalTrackingEvents).some((key) => trackingKey === key)) {
            trackEvent(modalTrackingEvents[trackingKey], { modalKey })
          }

          if (child.props.onClick) {
            child.props.onClick()
          }
        },
      })
    }
    return child
  })

  return <div className={styles.buttonWrapper}>{childrenWithTracking}</div>
}

export { ModalTitle, ModalFooter }

export default ConfirmationModal
