import { createSelector } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import { selectBusinessById } from '@redux/selectors/businesses'

const selectStoreById = (stores, id) => {
  return stores.items[id]
}

const selectAllStores = (state) => {
  return state.stores.items
}

const selectMyStores = createSelector([selectAllStores], (allStores) => {
  return Object.entries(allStores)
    .filter(([_storeId, store]: [string, any]) => store.isMyStore === true)
    .map(([storeId, store]: [string, any]) => ({
      id: Number(storeId),
      ...store,
    }))
})

const selectMyStoresWithBusinesses = createSelector(
  [(state) => state.stores, selectMyStores],
  (stores, myStores) => {
    const storesWithBusinesses = myStores.map((store) => {
      const business = selectBusinessById(stores, store.businessId)

      const clonedStore = cloneDeep(store)
      clonedStore.business = business
      return clonedStore
    })

    return storesWithBusinesses
  },
)

export { selectMyStoresWithBusinesses, selectAllStores, selectStoreById }
