import clsx from 'clsx'
import styles from './Grid.module.scss'

type Props = {
  className?: string
  children: React.ReactNode
  columns: number
  gap: string
}

const Grid = ({ className, children, columns, gap }: Props) => {
  const style = { '--columns': columns, '--gap': gap } as React.CSSProperties

  return (
    <div
      className={clsx(styles.root, className)}
      style={style}
    >
      {children}
    </div>
  )
}

export default Grid
