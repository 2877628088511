/**
 * Evaluates quiz answers to check whether all are answered correctly or not.
 * @param {array} questions - Questions as they are stored in redux
 * @param {array} userAnswers - Answers provided by the user as they are passed from the caller
 */
const checkQuizAnswers = (questions, userAnswers) => {
  const incorrectlyAnsweredQuestions = []
  questions.forEach((question, index) => {
    const correctAnswer = question.answers.find(({ isCorrect }) => isCorrect === true)
    const userAnswer = userAnswers[index]
    if (correctAnswer.body !== userAnswer) {
      incorrectlyAnsweredQuestions.push(index + 1)
    }
  })
  const correctAnswersCount = questions.length - incorrectlyAnsweredQuestions.length
  const areAnswersCorrect = incorrectlyAnsweredQuestions.length === 0
  return {
    areAnswersCorrect,
    incorrectlyAnsweredQuestions,
    correctAnswersCount,
  }
}

const getCompletedInductions = (inductions) => {
  return inductions.filter((induction: any) => induction.isComplete === true)
}

const getNonCompletedInductions = (inductions) => {
  return inductions.filter((induction: any) => induction.isComplete === false)
}

const YOUTUBE_URL_PREFIX = 'youtube.com/embed/'

const extractYoutubeVideoIdFromUrl = (url) => {
  const videoIdRegex = new RegExp(`${YOUTUBE_URL_PREFIX}(.*)`, 'g')
  const videoId = url.match(videoIdRegex)[0].replace(YOUTUBE_URL_PREFIX, '').split('?')[0]
  return videoId
}

const hasYoutubeVideo = (inductionContent) => {
  return inductionContent.includes(YOUTUBE_URL_PREFIX)
}

export {
  checkQuizAnswers,
  getCompletedInductions,
  getNonCompletedInductions,
  hasYoutubeVideo,
  extractYoutubeVideoIdFromUrl,
}
