export const infoItemTypes = {
  EMPTY: 'empty',
}

type Props = {
  type: string
  title: string
  description1: string
  description2?: string
  icon: string
}

const InfoItem = ({ type, title, description1, description2, icon }: Props) => {
  return (
    <div className="info-item-container">
      <div className={`card ${type}-item`}>
        <div className="card-header">
          <div className="icon-wrapper">
            <div className="icon">
              <img
                alt=""
                src={icon}
              />
            </div>
            <div className="text-content">
              <h3>{title}</h3>
              <p>
                <span>{description1}</span>
                {description2 && <span>{description2}</span>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoItem
