import { configureStore } from '@reduxjs/toolkit'
import analytics from '@redux/middleware/analytics'
import rootReducer from '@redux/reducers'
import { isProduction } from 'helpers/environment'

const reduxStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(analytics),
  devTools: isProduction === false,
})

export type AppDispatch = typeof reduxStore.dispatch
export type RootState = ReturnType<typeof reduxStore.getState>

export { reduxStore }
export default (initialState) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(analytics),
    preloadedState: initialState,
    devTools: isProduction === false,
  })
  return store
}
