import { COLLECTION_SWAP_MODAL_CLOSE } from '@redux/actionTypes'

const closeSwapModal = () => {
  return (dispatch) => {
    dispatch({
      type: COLLECTION_SWAP_MODAL_CLOSE,
    })
  }
}

export default closeSwapModal
