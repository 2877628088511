import { memo } from 'react'
import styles from './Address.module.scss'

type Props = {
  address: {
    addressLine1?: string
    addressLine2?: string
    addressLine3?: string
    city?: string
    postalCode?: string
  }
}

const Address = ({ address }: Props) => {
  return (
    <address className={styles.address}>
      {address &&
        Object.values(address).map((addressLine: string, index) => {
          return <span key={addressLine + index}>{addressLine}</span>
        })}
    </address>
  )
}

export default memo(Address)
