import { useEffect, useRef } from 'react'

const useTimeout = (callback, delay, runTimeout) => {
  const timeoutRef = useRef(null)
  const savedCallback = useRef(callback)

  useEffect(() => {
    if (runTimeout === true) {
      savedCallback.current = callback
    }
  }, [callback, runTimeout])

  useEffect(() => {
    if (runTimeout === true) {
      const tick = () => savedCallback.current()
      if (typeof delay === 'number') {
        timeoutRef.current = window.setTimeout(tick, delay)
        return () => window.clearTimeout(timeoutRef.current)
      }
    }
  }, [delay, runTimeout])

  return timeoutRef
}

export default useTimeout
