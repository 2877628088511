import { memo } from 'react'
import Item from 'components/Baskets/List/Content/Item'

type Props = {
  section: {
    id: number
  }
}

const Content = ({ section }: Props) => {
  return <Item basketId={section.id} />
}

export default memo(Content)
