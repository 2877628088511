export const cleanInduction = (induction) => {
  return {
    id: induction.id,
    title: induction.title,
    description: induction.description,
    isComplete: induction.is_complete,
    isActive: induction.active,
  }
}

export const cleanInductions = (inductions) => {
  return inductions.map((induction) => cleanInduction(induction))
}

export const cleanQuestions = (questions) => {
  return questions.map((question) => ({
    body: question.body,
    answers: question.answers.map((answer) => ({
      body: answer.body,
      isCorrect: answer.is_correct,
    })),
  }))
}
