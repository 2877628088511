import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchUser from '@redux/thunks/users/fetchUser'
import Debug from 'helpers/debug'
import { getCurrentUserId } from 'helpers/user'

const debug = Debug('components:common:user-avatar')

type Props = {
  className?: string
  userId?: number
  imageUrl?: string
}

const UserAvatar = ({ className = '', userId, imageUrl }: Props) => {
  const { t } = useTranslation()
  let avatarUrl = `https://cdn.olioex.com/${t('defaults.default-avatar')}`
  const user = useAppSelector((state) => state.users?.items[userId])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (imageUrl === undefined && userId !== undefined && Number(getCurrentUserId()) !== userId) {
      dispatch(fetchUser(userId)).catch(debug)
    }
  }, [])

  if (imageUrl !== undefined) {
    avatarUrl = imageUrl
  } else if (user !== undefined) {
    avatarUrl = user?.avatar?.small
  }

  return (
    <div className={`icon ${className}`}>
      <img
        alt=""
        data-testid="userAvatar"
        src={avatarUrl}
      />
    </div>
  )
}

export default UserAvatar
