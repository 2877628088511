import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { convertSecondsToMinutes } from 'helpers/time'
import VideoDurationIcon from 'images/VideoDurationIcon.svg'
import styles from './VideoDuration.module.scss'

type Props = {
  durationInSeconds: number
}

const VideoDuration = ({ durationInSeconds }: Props) => {
  const { t } = useTranslation()
  const durationInMinutes = useMemo(() => {
    return convertSecondsToMinutes(durationInSeconds)
  }, [durationInSeconds])

  if (typeof durationInSeconds !== 'number') {
    return null
  }

  return (
    <div className={styles.videoDuration}>
      <img
        src={VideoDurationIcon}
        alt=""
      />
      <span className={styles.videoDurationText}>
        {t('inductions.page.show.video_duration', { count: durationInMinutes })}
      </span>
    </div>
  )
}

export default VideoDuration
