import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdHowToReg } from 'react-icons/md'
import { modalKeys } from 'config/modals'
import { useModal } from 'hooks/application'
import Button from 'components/common/Button'

const { CLOSE_BASKET_CONFIRMATION } = modalKeys

type Props = {
  basketId?: number
}

const Footer = ({ basketId }: Props) => {
  const { t } = useTranslation()
  const { openModal } = useModal(CLOSE_BASKET_CONFIRMATION)

  return (
    <Button
      className="mark-collected-btn"
      leftContent={<MdHowToReg />}
      onClick={() => openModal({ basketId })}
    >
      {t('baskets.collected')}
    </Button>
  )
}

export default memo(Footer)
