import i18next from 'i18next'
import { DateTime } from 'luxon'
import { COLLECTION_TYPES } from 'config/collections'
import { modalKeys } from 'config/modals'
import { getCurrentUserId } from 'helpers/user'

const COLLECTION_STATES = {
  ACTIVE: 'active',
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
}

const MODAL_MODES = {
  OPTIONS: 'options',
  SUCCESS: 'success',
  CONFIRMATION: 'confirmation',
  WARNING: 'warning',
  FORBIDDEN: 'forbidden',
}

const STEP_COLLECTION = 'collection'
const STEP_DATE = 'collection-action-effective-date'
const STEP_INCREMENT = 'collection-pause-increment'
const STEP_TARGET_COLLECTION = 'collection-action-target'

const COLLECTION_ACTIONS = {
  UNASSIGN: 'unassign',
  SKIP: 'skip',
  PAUSE: 'pause',
  SWAP: 'swap',
}

const { UNASSIGN, SKIP, PAUSE, SWAP } = COLLECTION_ACTIONS

const getCollectionActionDetails = (t) => {
  return {
    [UNASSIGN]: {
      steps: {
        [STEP_COLLECTION]: {
          position: 1,
          header: t('your-collections.page.show.end-modal.choices-modal.subtitle-collection'),
        },
        [STEP_DATE]: {
          position: 2,
          header: t('your-collections.page.show.end-modal.choices-modal.subtitle-date'),
          immediateEffectText: t(
            'your-collections.page.show.end-modal.choices-modal.immediate-option',
          ),
        },
      },
      successHeader: t('your-collections.page.show.end-modal.success-modal.header'),
      optionsHeader: t('your-collections.page.show.end-modal.choices-modal.title'),
      atText: t('your-collections.page.show.end-modal.choices-modal.at'),
      footer: {
        confirmButton: t('your-collections.page.show.end-modal.choices-modal.buttons.confirm'),
        closeButton: t('your-collections.page.show.end-modal.choices-modal.buttons.close'),
      },
    },
    [SKIP]: {
      steps: {
        [STEP_COLLECTION]: {
          position: 1,
          header: t('your-collections.page.show.skip-modal.choices-modal.collection-subtitle'),
        },
        [STEP_DATE]: {
          position: 2,
          header: t('your-collections.page.show.skip-modal.choices-modal.skip-info-subtitle'),
        },
      },
      successHeader: t('your-collections.page.show.skip-modal.success-modal.header'),
      optionsHeader: t('your-collections.page.show.skip-modal.choices-modal.title'),
      atText: t('your-collections.page.show.skip-modal.choices-modal.at'),
      footer: {
        confirmButton: t('your-collections.page.show.skip-modal.choices-modal.buttons.confirm'),
        closeButton: t('your-collections.page.show.skip-modal.choices-modal.buttons.close'),
      },
    },
    [PAUSE]: {
      steps: {
        [STEP_COLLECTION]: {
          header: t('your-collections.page.show.pause-modal.choices-modal.subtitle-collection'),
          position: 1,
        },
        [STEP_DATE]: {
          position: 2,
          header: t('your-collections.page.show.pause-modal.choices-modal.subtitle-date'),
          immediateEffectText: t(
            'your-collections.page.show.pause-modal.choices-modal.immediate-option',
          ),
        },
        [STEP_INCREMENT]: {
          position: 3,
          header: t('your-collections.page.show.pause-modal.choices-modal.subtitle-increment'),
          incrementDescription: t(
            'your-collections.page.show.pause-modal.choices-modal.description-increment',
          ),
        },
      },
      successHeader: t('your-collections.page.show.pause-modal.success-modal.header'),
      description: '',
      optionsHeader: t('your-collections.page.show.pause-modal.choices-modal.title'),
      atText: t('your-collections.page.show.pause-modal.choices-modal.at'),
      footer: {
        confirmButton: t('your-collections.page.show.pause-modal.choices-modal.buttons.confirm'),
        closeButton: t('your-collections.page.show.pause-modal.choices-modal.buttons.close'),
      },
    },
    [SWAP]: {
      steps: {
        [STEP_COLLECTION]: {
          position: 1,
          header: t('your-collections.page.show.swap-modal.choices-modal.subtitle-collection'),
        },
        [STEP_TARGET_COLLECTION]: {
          position: 2,
          header: t(
            'your-collections.page.show.swap-modal.choices-modal.subtitle-target-collection',
          ),
        },
      },
      successHeader: t('your-collections.page.show.swap-modal.success-modal.header'),
      optionsHeader: t('your-collections.page.show.swap-modal.choices-modal.title'),
      atText: t('your-collections.page.show.swap-modal.choices-modal.at'),
      footer: {
        confirmButton: t('your-collections.page.show.swap-modal.choices-modal.buttons.confirm'),
        closeButton: t('your-collections.page.show.swap-modal.choices-modal.buttons.close'),
      },
    },
  }
}

const LUXON_FREQUENCY_MAP = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
  yearly: 'year',
}

const formatCollectionFullDate = ({ date, timezone }) => {
  if (!date) {
    return null
  }
  return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat(
    'EEE dd MMM, HH:mm',
  )
}

const getCollectionTypeTranslation = ({ t, type }) => {
  if (type === COLLECTION_TYPES.ONGOING) {
    return t('collections.available.type.ongoing')
  }

  if (type === COLLECTION_TYPES.ADHOC) {
    return t('collections.available.type.adhoc')
  }

  if (type === COLLECTION_TYPES.TEMPORARY) {
    return t('collections.available.type.temporary')
  }

  return null
}

const getCollectionFrequencyTranslation = ({ t, frequency }) => {
  if (frequency === 'daily') {
    return t('collection-slots.frequency.daily')
  }

  if (frequency === 'weekly') {
    return t('collection-slots.frequency.weekly')
  }

  if (frequency === 'fortnightly') {
    return t('collection-slots.frequency.fortnightly')
  }

  if (frequency === 'monthly') {
    return t('collection-slots.frequency.monthly')
  }

  if (frequency === 'yearly') {
    return t('collection-slots.frequency.yearly')
  }

  return null
}

const sortRotaItemsByDate = (storeIds, collections) => {
  const storeCollections = storeIds.map((storeId) => {
    return collections[storeId]
  })

  return storeCollections.sort(
    (a, b) => new Date(a.nextCollection).getTime() - new Date(b.nextCollection).getTime(),
  )
}

const getSwappableCollections = (storeCollections) => {
  return storeCollections
    .filter(
      (item) =>
        item.collectionState !== COLLECTION_STATES.UNASSIGNED && item.collectionState !== undefined,
    )
    .filter((item) => parseInt(item.userId) !== getCurrentUserId() && item.userId !== undefined)
    .filter((item) => item.type === COLLECTION_TYPES.ONGOING)
    .filter((item) => item.endDate === undefined)
}

const getActionModalKey = (mode) => {
  switch (mode) {
    case UNASSIGN:
      return modalKeys.UNASSIGN_COLLECTION
    case SKIP:
      return modalKeys.SKIP_COLLECTION
    case PAUSE:
      return modalKeys.PAUSE_COLLECTION
    case SWAP:
      return modalKeys.SWAP_COLLECTION
  }
}

export {
  COLLECTION_STATES,
  MODAL_MODES,
  STEP_COLLECTION,
  STEP_DATE,
  STEP_INCREMENT,
  STEP_TARGET_COLLECTION,
  COLLECTION_ACTIONS,
  getCollectionActionDetails,
  LUXON_FREQUENCY_MAP,
  formatCollectionFullDate,
  getCollectionTypeTranslation,
  getCollectionFrequencyTranslation,
  sortRotaItemsByDate,
  getSwappableCollections,
  getActionModalKey,
}
