import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import loginMagic from '@redux/thunks/users/loginMagic'
import { ERROR } from 'config/alert'
import { LOGIN_TYPES } from 'config/loginTypes'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import SceneHeader from 'components/common/SceneHeader'
import { Spacer } from 'components/layout/primitives'

const { MAGIC } = LOGIN_TYPES

const MagicLoginScene = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const authenticationError = useAppSelector((state) => state.users.authenticationError)

  const { token } = useParams()

  const handleLogin = () => {
    dispatch(loginMagic(token))
  }

  return (
    <div className="container">
      <SceneHeader title={t('magic_login.title')} />
      {authenticationError === MAGIC && (
        <>
          <Spacer x="1rem" />
          <Alert variant={ERROR}>{t('login.attempt_magic_login.error')}</Alert>
          <Spacer x="1rem" />
        </>
      )}
      <div className="card">
        <div className="card-content">
          <p>{t('magic_login.description')}</p>
        </div>
        <div className="card-action">
          <Button onClick={handleLogin}>{t('magic_login.login')}</Button>
        </div>
      </div>
    </div>
  )
}

export default MagicLoginScene
